import React, { useEffect, useState } from 'react';
import { Grid, Select } from "semantic-ui-react";
import { IntegrationSocialWork } from '../../enums';
import DatePickerForm from "../common/DatePickerForm";
import InputForm from "../common/InputForm";
import SelectForm from '../common/SelectForm';
import SwitchComponent from "../common/SwitchComponent";
import ButtonBox from "../utils/ButtonBox";
import "./Document.css";
import DiagnosticModal from "./modals/DiagnosticModal";
import ReactSelect from 'react-select';
import api from '../../api';

export default function DocumentMedicalOrder({
	control,
	errors,
	dateMedicalOrder,
	register,
	loadingInput,
	idSocialWork,
	infoSocialWork,
	setDataToast,
	setDateMedicalOrder,
	onBlurInputSearch,
	isView,
	numSiniester,
	numAutorization,
	coinsuranceAmout,
	setStateOfCheckbox,
	stateOfCheckbox,
	isEdit,
	diagnosticModal,
	setDiagnosticModal
}) {
	const [openDiagnosticModal, setOpenDiagnosticModal] = useState(false);

	const [loadingDiagnostic, setLoadingDiagnostic] = useState(false);
	const [diagnoses, setDiagnoses] = useState([]);
	const [inputValue, setInputValue] = useState('');

	useEffect(() => {
		if (idSocialWork.id === IntegrationSocialWork.prevencionSalud && infoSocialWork.hasIntegration && inputValue.length >= 3) {
			setLoadingDiagnostic(true);
			let params = { obraSocialId: idSocialWork.id, texto: inputValue }

			api
				.GetDiagnosisForSelect(params)
				.then((resp) => {
					let fetchedDiagnoses = resp.data.map(item => {
						return {
							value: item.codigo,
							name: `(${item['codigo']}) ${item['nombre']}`
						}
					});
					setDiagnoses(fetchedDiagnoses);
					setLoadingDiagnostic(false);
				})
				.catch((err) => {
					setDataToast({
						active: true,
						message: err?.response?.data?.errores ?? "Ocurrió un Error",
						type: 'error',
					});
				})
				.finally(() => setLoadingDiagnostic(false));
		}
	}, [idSocialWork, inputValue]);

	//crear funcion que haga un llamado al BE
	//validacion: se tiene que llamar al BE si tiene al menos tres caracteres 
	const handleInputChange = (event) => {
		setInputValue(event);
	};

	return (
		<Grid.Row>
			<Grid.Column id='column-medical-order-one'>
				<InputForm
					disabled={isView}
					register={register({ required: true })}
					isRequired={true}
					type={'text'}
					label={'Matr. Prescriptor'}
					name={'enrollment'}
					control={control}
					error={errors}
					loading={loadingInput.enrollment}
					onBlur={(event) => onBlurInputSearch(event)}
				/>
				<InputForm
					disabled={isView}
					register={register({ required: true })}
					isRequired={true}
					type={'text'}
					label={'Nombre'}
					name={'nameMedicalOrder'}
					control={control}
					error={errors}
				/>
				<InputForm
					disabled={isView}
					register={register({ required: true })}
					isRequired={true}
					type={'text'}
					label={'Apellido'}
					name={'surnameMedicalOrder'}
					control={control}
					error={errors}
				/>
			</Grid.Column>
			<Grid.Column id='column-medical-order-one'>
				<DatePickerForm
					disabled={isView}
					label='Fecha'
					name='date'
					dateValue={dateMedicalOrder}
					handleDateChange={(date) => {
						if (!isNaN(date)) {
							setDateMedicalOrder(date)
						}
					}}
					minDateMessage='Fecha ingresada es menor a la requerida'
				/>
				{idSocialWork.id == IntegrationSocialWork.prevencionSalud && infoSocialWork.hasIntegration && !isView ?
					<Grid.Row style={{ padding: "2px" }}>
						<SelectForm
							disabled={isView}
							label={"Diagnóstico"}
							name={"diagnosis"}
							loading={loadingDiagnostic}
							control={control}
							menuItemList={diagnoses}
							as={ReactSelect}
							error={errors}
							onInputChange={handleInputChange}
						/>
					</Grid.Row>
					: diagnosticModal.show ?
						<InputForm
							disabled={isView || diagnosticModal.show}
							type='text'
							label='Diagnóstico'
							name='diagnosis'
							value={diagnosticModal.data?.descripcion}
							placeholder="Lista Diagnósticos..."
							error={errors}
						>
							{diagnosticModal.show && !isView && !isEdit &&
								<div className='iconsIntegration'>
									<ButtonBox
										buttonToShow="showModalDiagnostic"
										name="showModalDiagnostic"
										HandleOnClik={() => setOpenDiagnosticModal(true)} />
								</div>}
						</InputForm>
						:
						<InputForm
							disabled={isView}
							type={'text'}
							label={'Diagnóstico'}
							name={'diagnosis'}
							control={control}
							error={errors}
						/>
				}
				{numSiniester &&
					(<InputForm
						disabled={isView}
						isRequired={false}
						type={'number'}
						label={'Nro. Siniestro'}
						name={'siniester'}
						control={control}
						error={errors}
					/>)}
				<InputForm
					disabled={(isView || !numAutorization)}
					register={register({ required: true })}
					isRequired={true}
					type={'number'}
					label={'Nro. Autorización'}
					name={'autorNum'}
					control={control}
					error={errors}
				>
					{(!isView && !isEdit) && stateOfCheckbox.hasIntegration &&
						<div className='iconsIntegration cm-charge'>
							<SwitchComponent
								label="Carga Manual"
								abbr="CM"
								name="manualCharge"
								onChange={(e) => setStateOfCheckbox(e)}
								stateOfChecked={stateOfCheckbox.manualCharge} />
						</div>}
				</InputForm>
				<InputForm
					disabled={(isView || !coinsuranceAmout)}
					isRequired={false}
					type={'number'}
					label={'Coseguro'}
					name={'coseguro'}
					control={control}
					error={errors}
				/>
				{diagnosticModal.show && openDiagnosticModal &&
					<DiagnosticModal
						idSocialWork={idSocialWork}
						setDiagnosticModal={setDiagnosticModal}
						diagnosticModal={diagnosticModal}
						openDiagnosticModal={openDiagnosticModal}
						setOpenDiagnosticModal={setOpenDiagnosticModal}
						setDataToast={setDataToast} />}
			</Grid.Column>
		</Grid.Row>
	);
}
