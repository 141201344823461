import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Dimmer,
  Grid,
  Header,
  Icon,
  Loader,
} from "semantic-ui-react";
import api from "../../../api";
import "./ImportFile.css";

export default function ImportFile({ setDataToast, importForLiquidation = false }) {
  const [selectedFile, setSelectedFile] = useState();
  const [loadingActive, setLoadingActive] = useState();
  const [canUpload, setCanUpload] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const onChangeHandler = (event) => {
  
    let arraySave = selectedFile ? [...selectedFile] : [];
    let file = event.target.files.item(0);
    let index = arraySave.indexOf(file);
    var megas = file.size / 1024 / 1024;
    if (megas <= 5) {
      if (index === -1) {
      
        arraySave.push(file);
        setSelectedFile(arraySave);
        let reader = new FileReader();
        reader.onload = function (event) {};
        reader.readAsText(file);
      }
      setCanUpload(true);
    } else {
      setDataToast({
        active: true,
        message: "La imagen no puede ser superior a 5MB",
        type: "warning",
      });
    }
  };

  const removeFile = (file) => {
    let arraySave = selectedFile ? [...selectedFile] : [];
    let index = arraySave.indexOf(file);
    if (index > -1) {
      arraySave.splice(index, 1);
      setSelectedFile(arraySave);
    }
    if (index == 0) {
      setCanUpload(false);
    }
  };

  const handleClickCancel = () => {
    if (location.state !== undefined && location.state.fromPreAuthForm){
      history.push("/pacientes/lista")
    }else{
      history.goBack();
    }
  };

  const CardsUploadFile = ({ item }) => {
    return (
      <Grid.Row>
        <Card.Group className="centered-cards-process-upload">
          <Card className="width-card-process-upload">
            <Card.Content className="width-card-content-process-upload">
              <Card.Description>
                {item.name}
                <Icon
                  onClick={() => removeFile(item)}
                  className="icon-button-trash"
                  style={{ float: "right" }}
                  name="trash alternate"
                  size="large"
                />
              </Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
      </Grid.Row>
    );
  };

  const uploadFile = () => {
    let formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      formData.append("images", selectedFile[index]);
    }
    setLoadingActive(true);
      api
      .uploadImageDocument(id, formData)
      .then((resp) => {
				setDataToast({
					active: true,
					message: 'Imagen subida correctamente',
					type: 'success',
				});
			})
      .catch((err) => {
         setDataToast({
           active: true,
           message: err.response.data.errores || "Acaba de ocurrir un error",
           type: "error",
         });
      })
      .finally(()=>{
        setLoadingActive(false);
        history.goBack();
      })
      .finally(()=>{
        if (location.state !== undefined && location.state.fromPreAuthForm){
          history.push("/pacientes/lista");
        }else{
          history.goBack();
        }
      })
    }
    const PrincipalView = () => {
      return (
        <>
          <div className="file-upload image-upload-wrap">
            <input
              type="file"
              name="sampleFile"
              className="file-upload-input"
              onChange={(event) => onChangeHandler(event)}
              accept="image/x-png,image/gif,image/jpeg"
            />
            <div className="drag-text">
              <Icon className="upload-icon" name="cloud upload" size="huge" />
              <h2>Subir Archivo</h2>
              <h4 style={{ marginTop: "-10px" }}>
                Arrastre aquí los archivos PNG o JPG
              </h4>
            </div>
          </div>
          <Grid.Row className="place-content-center">
            <div>
              <Button
                style={{ marginRight: 50 }}
                onClick={() => handleClickCancel()}
              >
                Cancelar
              </Button>
              <Button primary disabled={!canUpload} onClick={() => uploadFile()}>
                Continuar
              </Button>
            </div>
          </Grid.Row>
        </>
      );
    };
  
    return (
      <>
        {loadingActive && (
          <Dimmer className="loader-fixed" active inverted>
            <Loader size="big">Subiendo archivos...</Loader>
          </Dimmer>
        )}
        <Grid>
          <Grid.Row>
            <Header as="h2" icon textAlign="center">
              <Header.Content>Importar Imágenes</Header.Content>
            </Header>
          </Grid.Row>
          {selectedFile &&
            selectedFile.map((item) => (
              <CardsUploadFile key={item.name} item={item} />
            ))}
          <PrincipalView />
        </Grid>
      </>
    );
  };
