import { Tooltip } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import CheckIcon from "@material-ui/icons/CheckSharp";
import CloseIcon from "@material-ui/icons/CloseSharp";
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import TableRow from "@material-ui/core/TableRow";
import MoneyOffOutlinedIcon from '@material-ui/icons/MoneyOffOutlined';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ImageIcon from "@material-ui/icons/Image";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";
import { StatusOfDocument, TypeRole } from "../../enums";
import PopupImage from "../utils/popupImage";
import "./Period.css";
import ButtonBox from "../utils/ButtonBox";
import { useSelector } from "react-redux";

export default function PeriodNewEditList({
  dataTable,
  selected,
  setSelected,
  returnsComment,
  setReturnsComment,
  role,
  periodId,
  RemoveDocFromPeriod,
  MessageNotification,
  setPage,
  reloadData,
}) {
  const history = useHistory();
  const hasManagment = useSelector(({managment}) => managment.hasManagment);

  const handleClick = (event, docSelected) => {

    const selectedIndex = selected.indexOf(docSelected.documentoId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, docSelected.documentoId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (documentoId) => selected.indexOf(documentoId) !== -1;

  const TableRowData = ({ item }) => {
    const isItemSelected = isSelected(item.documentoId);
    return (
      <TableRow colSpan={6} key={item.documentoId}>
        <TableCell className="letter-body-table p-cell">
          {!periodId && (
            <Checkbox
              disabled={item.imagenes.length === 0}
              onClick={(event) => handleClick(event, item)}
              className="no-padding-checkbox p-cell"
              checked={isItemSelected}
              color="primary"
            />
          )}
          {moment(item.fechaPrimeraSesion).format("DD-MM-YYYY")}
        </TableCell>
        <TableCell className="p-cell">
        {item.apellidoPaciente} {item.nombrePaciente}
        </TableCell>
        <TableCell className="p-cell">{item.documentoPaciente}</TableCell>
        <TableCell className="p-cell">{item.obraSocial}</TableCell>
        <TableCell className="p-cell">{item.numeroAutorizacion}</TableCell>
        {TypeRole.Admin === role &&
          <TableCell className="p-cell">
          {item.estadoId === StatusOfDocument.Verified ? 
            <Tooltip title="Verificado" placement='top' arrow>
              <CheckIcon className='c-icon-success' />
            </Tooltip>
            : item.estadoId === StatusOfDocument.NotVerified ? 
            <Tooltip title="No Verificado" placement='top' arrow>
              <CloseIcon className='c-icon-cancel' />
            </Tooltip>
            : item.estadoId === StatusOfDocument.Envoiced && hasManagment ? 
            <Tooltip title="Facturado" placement='top' arrow>
              <AttachMoneyOutlinedIcon className="c-icon-success"/>
            </Tooltip>
            : item.estadoId === StatusOfDocument.NotEnvoiced && hasManagment ?
            <Tooltip title="No Facturado" placement='top' arrow>
              <MoneyOffOutlinedIcon className='c-icon-cancel'/>
            </Tooltip>
            :
            <Tooltip title="Pendiente" placement='top' arrow>
              <CheckBoxOutlineBlankIcon className='c-icon-edit' />
            </Tooltip>}
          </TableCell>}
        <TableCell className="p-cell">
          {!periodId ? (
            <Button.Group>
              <IconButton
                className="btn-circle-green"
                onClick={() =>
                  history.push("/paciente/importar/" + item.documentoId)
                }
                aria-label="addImage"
              >
                <Tooltip placement="top" title="Nueva Imagen">
                  <ImageIcon />
                </Tooltip>
              </IconButton>
              
                <PopupImage
                  reloadData={reloadData}
                  MessageNotification={MessageNotification}
                  dontDelete={false}
                  data={item.imagenes}
                  document={item.documentoId}
                  title={
                    "Imágenes de: " +
                    item.nombrePaciente +
                    " " +
                    item.apellidoPaciente
                  }
                />
              
            </Button.Group>
          ) : (
            <Button.Group>
                <PopupImage
                  reloadData={reloadData}
                  MessageNotification={MessageNotification}
                  dontDelete={false}
                  data={item.imagenes}
                  document={item.documentoId}
                  title={
                    "Imágenes de: " +
                    item.nombrePaciente +
                    " " +
                    item.apellidoPaciente
                  }
                />
                {!item.integracion && (item.estadoId !== StatusOfDocument.Envoiced) &&
                    <ButtonBox name="edit" buttonToShow="edit" HandleOnClik={()=>{
                      history.push(
                      '/paciente/editar/' + item.documentoId,
                      localStorage.setItem('viewListSession', false),
                      localStorage.setItem('calculateSessions', true))
                    }}/>
                }
                {item.estadoId !== StatusOfDocument.Envoiced &&
                <ButtonBox name="delete" buttonToShow="delete" HandleOnClik={()=>{
                  if(role === TypeRole.Professional){
                      RemoveDocFromPeriod(item.documentoId);
                    }else{
                      setReturnsComment({
                        ...returnsComment,
                        showModal: true,
                        id: item.documentoId,
                        patient: item.nombrePaciente + " "  + item.apellidoPaciente
                      });
                    }
                }}/>}
            </Button.Group>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataTable.map((n) => {
        if (n.imagenes.length > 0) return n.documentoId;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <>
      <Grid.Row>
        <TableContainer component={Paper} id="color-letter-table-tasks">
          <Table aria-label="spanning table">
            <TableHead className="color-letter-inTable">
              <TableRow>
                <TableCell className="letter-title-table not-padding-table-task">
                  {!periodId && (
                    <Checkbox
                      checked={
                        dataTable.length > 0 &&
                        selected.length === dataTable.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                      color="primary"
                    />
                  )}
                  <b>Fecha</b>
                </TableCell>
                <TableCell className="letter-title-table">
                  <b>Paciente</b>
                </TableCell>
                <TableCell className="letter-title-table">
                  <b>DNI</b>
                </TableCell>
                <TableCell className="letter-title-table">
                  <b>Obra Social</b>
                </TableCell>
                <TableCell className="letter-title-table">
                  <b>Nro. Autorización</b>
                </TableCell>
                {TypeRole.Admin === role &&
                <TableCell className="letter-title-table">
                  <b>Estado</b>
                </TableCell>}
                <TableCell className="letter-title-table">
                  <b>Opciones </b>
                </TableCell>
              </TableRow>
            </TableHead>
            {dataTable && (
              <TableBody>
                {dataTable.map((item) => (
                  <TableRowData key={item.documentoId} item={item} />
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid.Row>
    </>
  );
}
