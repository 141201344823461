import React from 'react';
import api from '../../../api';
import ButtonBox from '../ButtonBox';
import PhotoView from './PhotoView';
import { Dimmer, Loader } from "semantic-ui-react";

export default function PopupImage({
	title,
	data,
	dataToast,
	document,
	dontDelete,
	professionalId,
	MessageNotification,
	reloadData,
}) {
	let tipoUsuario = JSON.parse(localStorage.getItem('TypeRole'));
	const dataUrl = [];
	const [abrirModal, setAbrirModal] = React.useState(false);
	const [photo, setPhoto] = React.useState({
		imagenes: [],
	});
	const [loadingActive, setLoadingActive] = React.useState(false);

	const OpenPreviewImage = () => {
		PreviewImg(data, document);
		setAbrirModal(true);
	};
	const PreviewImg = (images, document) => {
		setLoadingActive(true);
		let arrayFunctions = [];
		arrayFunctions = downloadImagesByDocument(images, document);
		downloadImageFromDB(arrayFunctions);
	};

	const downloadImageFromDB =(arrayFunctions)=>{
		Promise.all(arrayFunctions)
		.then((res) => {
			res.forEach((item) => {
				const imageUrl = URL.createObjectURL(item.data);
				dataUrl.push({
					src: imageUrl,
					title,
				});
			});
			setPhoto({
				imagenes: dataUrl,
			});
		})
		 .catch((err) => {
			MessageNotification(err.response.data.errores, "error");
		 })
		 .finally(() => setLoadingActive(false));
	}

	const downloadImagesByDocument = (images, document)=>{
		let returnInfo = [];
		if (images.length > 1) {
			images.forEach((item) => {
				let image = {
					documentoId: document,
					imagenId: item.id,
				};
				returnInfo.push(api.imagesDownload(image));
			});
		} else {
			let item = images[0];
			let image = {
				documentoId: document,
				imagenId: item.id,
			};
			returnInfo.push(api.imagesDownload(image));
		}
		return returnInfo;
	}

	return (
		<>
		{	loadingActive ? (
					<Dimmer className='loader-fixed' active inverted>
						<Loader size='big'>Cargando..</Loader>
					</Dimmer>
				) : (
					<>
						{data.length > 0 && <ButtonBox name='viewPhotos' buttonToShow='viewPhotos' HandleOnClik={OpenPreviewImage}/>}

						{photo.imagenes.length > 0 && 
							<PhotoView
								professionalId={professionalId}
								tipoUsuario={tipoUsuario}
								imagenes={photo.imagenes}
								data={data}
								document={document}
								dontDelete={dontDelete}
								dataToast={dataToast}
								MessageNotification={MessageNotification}
								reloadData={reloadData}
								abrirModal={abrirModal}
								setAbrirModal={setAbrirModal}
								setLoadingActive={setLoadingActive}
							/>}
					</>
				)
			}
		</>
	);
}
