import React from 'react';
import GridMui from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckIcon from "@material-ui/icons/CheckSharp";
import CloseIcon from "@material-ui/icons/CloseSharp";
import Pagination from "@material-ui/lab/Pagination";
import { saveAs } from "file-saver";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dimmer, Grid, Loader } from "semantic-ui-react";
import "../../App.scss";
import api from "../../api";
import "./Period.css";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Tooltip,
  Typography
} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import { StatusOfDocument } from "../../enums";
import ButtonBox from "../utils/ButtonBox";
import { useSelector } from 'react-redux';

export default function PeriodNewEditList({
  loadData,
  dataTable,
  totalCount,
  loadForPage,
  setDataToast,
  isEdit,
  isAdmin,
  isProfessional,
  setEdit,
  page,
  setPage,
  handleClickSearch,
}) {
  const history = useHistory();
  const handleChange = (event, value) => {
    setPage(value);
    loadData(value);
    window.scrollTo(0, 0);
  };
  const [socialWorkByPeriod, setSocialWorkByPeriod] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});
  const hasManagment = useSelector(({managment}) => managment.hasManagment);


  const saveChecks = (id, proffesionalName) => {
    setIsLoading(true);
    const socialWorks = Object.entries(state).map(([key,value]) => {
      return {
        obraSocialId: +key,
        marcada: value
      }
    });
    api
      .updateVerifiedDocuments(id, proffesionalName, socialWorks)
      .then(() => {
        setDataToast({
          active: true,
          message: "Actualizado correctamente",
          type: "success",
        });
        handleClickSearch();
      })
      .catch((err) => {
        setDataToast({
          active: true,
          message: err.response.data.errores || "Acaba de ocurrir un error",
          type: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setOpen(false);
      });
  };

  const viewListSocialWorks = (id, professionalId) => {
    setIsLoading(true);
    api
      .getDocumentByPeriodId(id, professionalId)
      .then((res) => {
        let socialWorkState = {};
        let dataApi = res.data;
        let arraySocialWorks = [];
        dataApi.forEach((item) => {
          let model = {
            socialWork: item.socialWork,
            count: item.count,
            socialWorkId: item.documentDataResponses[0].obraSocialId,
            proffesional: professionalId,
            periodId: id,
          };
          arraySocialWorks.push(model);
          socialWorkState = {
            ...socialWorkState,
            [item.documentDataResponses[0].obraSocialId]: item.documentDataResponses.every(doc => doc.estadoId === StatusOfDocument.Verified || doc.estadoId === StatusOfDocument.Envoiced),
          }
        });
        setState(socialWorkState);
        setSocialWorkByPeriod(arraySocialWorks);
        setOpen(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setDataToast({
          active: true,
          message: err.response.data.errores || "Acaba de ocurrir un error",
          type: "error",
        });
        setOpen(false);
        setIsLoading(false);
      });
  };

  const exportExcelPatients = (params)=>{
    setIsLoading(true);
    api 
    .exportExcelByProfessionalId(params)
    .then(res=>{
      let extension = 'xlsx';
      let tempFileName =`Presentacion de Ordenes -- ${params.mes}-${params.anio} -- ${params.profesionalNombre}`;
      let fileName = `${tempFileName}.${extension}`;

      let blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel',
      });
      saveAs(blob, fileName);

      setDataToast({
        active: true,
        message: 'Exportado correctamente',
        type: 'success',
      });
    })
    .catch(err=>{
      setDataToast({
        active: true,
        message: err.response.data.errores || "Acaba de ocurrir un error",
        type: "error",
      });
    })
    .finally(() => setIsLoading(false));
  }

  const printPeriods = (documentId, professionalId) => {
    setIsLoading(true);
    api
      .printSocialWorksPeriods(documentId, professionalId)
      .then((resp) => {
        let extension = "pdf";
        let tempFileName = "Obras Sociales por Periodo";
        let fileName = `${tempFileName}.${extension}`;
        let blob = new Blob([resp.data], {
          type: "application/pdf",
        });
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        setDataToast({
          active: true,
          message: err.response.data.errores || "Acaba de ocurrir un error",
          type: "error",
        });
      })
      .finally(() => setIsLoading(false));
  };
  const changeCheck = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.checked,
    });
  };

  const ViewRowData = ({ item }) => {
    return (
      <GridMui container justifyContent="center" alignItems="center">
        <GridMui item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                key={item.socialWorkId}
                checked={state[item.socialWorkId]}
                onChange={(e)=>changeCheck(e)}
                
                id={item.socialWorkId}
              />
            }
          />
        </GridMui>

        <GridMui item xs={11}>
          <DialogContentText>
            {item.socialWork + " (" + item.count + ")"}
          </DialogContentText>
        </GridMui>
      </GridMui>
    );
  };

  const ViewPopUpRowData = ({ item }) => {
    return (
      <GridMui container justifyContent="center" alignItems="center">
        <GridMui item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                key={item.socialWorkId}
                checked={state[item.socialWorkId]}
                onChange={(e)=>changeCheck(e)}
                id={item.socialWorkId}
              />
            }
          />
        </GridMui>

        <GridMui item xs={11}>
          <DialogContentText>
            {item.socialWork + " (" + item.count + ")"}
          </DialogContentText>
        </GridMui>
      </GridMui>
    );
  };

  const TableRowData = ({ item }) => {
    let editPeriod = false;
		//let printPeriod = false;

		let today = new Date();

		let fechaCierre = new Date(item.fechaCierre);
    // let fechaFin = new Date(item.fechaFin);
    let fechaRevision = new Date(item.fechaFinRevision)

		today.setHours(0);
		today.setMinutes(0);
		today.setSeconds(0);
		today.setMilliseconds(0);
    
    
		if (isProfessional) {
			/*if (fechaCierre < today) {
				editPeriod = false;
				printPeriod = false;
			} else */
      if (fechaCierre >= today) {
				editPeriod = true;
			}
			/*if (fechaCierre > today) {
				printPeriod = true;
			}*/
		} 
    if(isAdmin){
      editPeriod = true;
    }
		setEdit(isEdit);

		return (
			<TableRow colSpan={5} key={item.id}>
				<TableCell className='p-cell c-text-center'>{item.anio}</TableCell>
				<TableCell className='p-cell c-text-center'>{item.mes}</TableCell>
				<TableCell className='p-cell c-text-center'>
					{item.cantidadDocumentos}
				</TableCell>
				<TableCell className='p-cell c-text-center'>
					{item.profesionalNombre}
				</TableCell>
				{!isAdmin && (
					<TableCell className='p-cell c-text-center'>
						{fechaCierre.toLocaleDateString()}
					</TableCell>
				)}
				{isAdmin && (
					<>
						<TableCell className='p-cell c-text-center'>
							{item.cantidadObrasSociales}
							{isAdmin && (
                <ButtonBox name="viewPresentedSocialWorks" buttonToShow="viewPresentedSocialWorks" HandleOnClik={() =>
                  viewListSocialWorks(
                    item.id,
                    item.profesionalId,
                    item.profesionalNombre
                  )}/>
							)}
						</TableCell>
						<TableCell className='p-cell c-text-center'>
              <Grid.Row style={{display: "flex", justifyContent: "center"}}>
             <Tooltip title="Verificados" placement='top' arrow>
              <Typography className="cont-status-info status-icon-verified">
                    <b>{item.verificados} </b><CheckIcon className='c-icon-success status-icons'/>
                </Typography>
             </Tooltip>
             <Tooltip title="No Verificados" placement='top' arrow>
              <Typography className="cont-status-info status-icon-notVerified">
                  <b>{item.noVerificados} </b><CloseIcon className='c-icon-cancel status-icons'/>
              </Typography>
             </Tooltip>
             {hasManagment && 
             <Tooltip title="Facturados" placement='top' arrow>
              <Typography className="cont-status-info status-icon-envoiced">
                  <b>{item.facturados} </b><AttachMoneyOutlinedIcon className="c-icon-success status-icons"/>
                </Typography>
             </Tooltip>}
             <Tooltip title="Pendientes" placement='top' arrow>
              <Typography className="cont-status-info status-icon-pending">
                  <b>{item.pendientes} </b><CheckBoxOutlineBlankIcon className='c-icon-edit status-icons'/>
              </Typography>
             </Tooltip>
              </Grid.Row>
						</TableCell>
					</>
				)}
				{isAdmin && (
					<TableCell className='p-cell c-text-center'>{fechaCierre.toLocaleDateString()}</TableCell>
				)}
				<TableCell className='p-cell c-text-center'>
					<Button.Group>

            {editPeriod && isEdit && 
            <ButtonBox name="edit" buttonToShow="edit" HandleOnClik={() =>
                history.push(
                  '/periodo/editar/' + item.id,
                  localStorage.setItem('professionalId', item.profesionalId)
                )}/>}

            <ButtonBox name="print" buttonToShow="print" HandleOnClik={() => printPeriods(item.id, item.profesionalId)}/>
						
            {isAdmin &&
            <ButtonBox name="printExcel" buttonToShow="printExcel" HandleOnClik={() => {
                let params = {
                  professionalId: item.profesionalId,
                  mes: item.numeroMes,
                  anio: +item.anio,
                  id: item.id,
                  profesionalNombre: item.profesionalNombre
                }
                exportExcelPatients(params);
              }}/>}
              
					</Button.Group>
				</TableCell>
			</TableRow>
		);
  };

  return (
    <>
      {isLoading && (
        <Dimmer className="loader-fixed" active inverted>
          <Loader size="big">Cargando..</Loader>
        </Dimmer>
      )}
      <Grid.Row>
        <TableContainer component={Paper} id="color-letter-table-tasks">
          <Table aria-label="spanning table">
            <TableHead className="color-letter-inTable">
              <TableRow colSpan={4}>
                <TableCell className="letter-title-table not-padding-table-task c-text-center">
                  <b>Año</b>
                </TableCell>
                <TableCell className="letter-title-table c-text-center">
                  <b>Mes</b>
                </TableCell>
                <TableCell className="letter-title-table c-text-center">
                  <b>Cantidad</b>
                </TableCell>
                <TableCell className="letter-title-table c-text-center">
                  <b>Profesional</b>
                </TableCell>
                {isAdmin && (
                  <>
                    <TableCell className="letter-title-table c-text-center">
                      <b>Cantidad Obras Sociales</b>
                    </TableCell>
                    <TableCell className="letter-title-table c-text-center">
                      <b>Estado</b>
                    </TableCell>
                  </>
                )}
                <TableCell className="letter-title-table c-text-center">
                  <b>Fecha de Cierre</b>
                </TableCell>
                <TableCell className="letter-title-table c-text-center">
                  <b>Opciones</b>
                </TableCell>
              </TableRow>
            </TableHead>
            {dataTable && (
              <TableBody>
                {dataTable.map((item, index) => (
                  <TableRowData item={item} key={index} />
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid.Row>
      <Grid.Row centered>
        {dataTable && (
          <Pagination
            className="pagination"
            count={
              totalCount / loadForPage > parseInt(totalCount / loadForPage)
                ? parseInt(totalCount / loadForPage) + 1
                : parseInt(totalCount / loadForPage)
            }
            page={page}
            onChange={handleChange}
          />
        )}
      </Grid.Row>
      {socialWorkByPeriod && !isLoading && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{ style: { minWidth: 500 } }}
        >
          <DialogTitle> Obras Sociales Presentadas </DialogTitle>
          <DialogContent>
            {socialWorkByPeriod.length > 1 ? (
              socialWorkByPeriod.map((item) => <ViewRowData item={item} />)
            ) : (
              <ViewPopUpRowData item={socialWorkByPeriod[0]} />
            )}
          </DialogContent>
          <DialogActions style={{display: "flex", justifyContent: "center"}}>
            <Button
              className="c-btn-success"
              onClick={() => {saveChecks(socialWorkByPeriod[0].periodId, socialWorkByPeriod[0].proffesional); }}>
              Guardar
            </Button>
            <Button onClick={() => setOpen(false)}>Atras</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
