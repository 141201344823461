import { InputAdornment, makeStyles } from "@material-ui/core/";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import ReactSelect from 'react-select';
import {
    Grid, Icon
} from "semantic-ui-react";
import api from '../../../../api';
import { IntegrationSocialWork, StatusCode, TypeRole } from '../../../../enums';
import { IModalOsForRegional } from '../../../../services/interfaces/Configurations/IModalOsForRegional';
import { selectTypeFormat } from '../../../../services/interfaces/utils/IUtilDeclaration';
import GeneralModalCrud from '../../../utils/GeneralModalCrud';
import { _DEFAULT_LOADER_SELECT } from '../defaultValues';
import { useSelector } from "react-redux";

const objStyle = makeStyles({
    columnSpace: {display: "flex", flexDirection: "column", gap: 18}
});

const socialWorksIds = [IntegrationSocialWork.sancorSalud, IntegrationSocialWork.avalian, IntegrationSocialWork.prevencionSalud];

const ModalOsForRegional: React.FC<IModalOsForRegional> = ({
    isEdit,
    isCreate,
    isView,
    titleOfModal,
    values,
    openModal,
    errors,
    stateOfCheckbox,
    roleType,
    setValues,
    setStateCheckBox,
    MessageNotification,
    CloseModal,
    SendValues
}: any) => {
    const [socialWorks, setSocialWorks] = React.useState<selectTypeFormat[]>([]);
    const [loaderSelect, setLoaderSelect] = React.useState(_DEFAULT_LOADER_SELECT);
    const hasManagment = useSelector<any>(({managment}) => managment.hasManagment);

    const customStyles = objStyle();

    React.useEffect(()=>{
        GetSocialWorkForAssign();
    },[])

    async function GetSocialWorkForAssign(){
        try{
            setLoaderSelect({...loaderSelect,socialWork: true});
            let params: any = {
                asignadas: (roleType === TypeRole.Admin || roleType === TypeRole.SuperAdmin) ? false : true,
                confirmada: (roleType === TypeRole.Admin || roleType === TypeRole.SuperAdmin) ? false : true,
            }
            const {status, data} = await api.GetSocialWorkForSelect(params);
            if (status === StatusCode.Ok){
                const socialWorkList = data.map(item => {
                    return {value: item.id, label: item["nombre"]}
                });
                setSocialWorks(socialWorkList);
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores,"error");
        }finally{
            setLoaderSelect(_DEFAULT_LOADER_SELECT);
        }
	}
    function RestoreIntegrationValue(e){
         setStateCheckBox({
            ...stateOfCheckbox,
            integracion: false,
            requiereToken: false,
            convenio: e.target.checked
        });
        setValues({
            ...values,
            apikey: null,
            clave: null,
            requiereToken: null,
            email: null,
            terminal: null,
            codigoPrestador: null
        })
    }

    function OnChangeSocialWork(socialWork: selectTypeFormat){
        setValues({...values, id: socialWork === null ? null : socialWork.value});
    }
 return(
    <GeneralModalCrud
    openModal={openModal}
    HandleCloseModal={CloseModal}
    HandleSubmit={SendValues}
    titleOfModal={titleOfModal}
    styles={"contModalGrid cont-default-modal"}
    >
        <Grid.Column className={customStyles.columnSpace}>
            <ReactSelect
                placeholder={`${values.nombre ?? "Obras sociales..."}`}
                options={socialWorks}
                isLoading={loaderSelect.socialWork}
                isClearable={true}
                isDisabled={isEdit || isView}
                className={errors.obraSocialId ? "errorNotificationInput" : ""}
                onChange={(e)=>OnChangeSocialWork(e)}
                styles={{
                    control: (s)=>({...s, maxWidth: 290})
                }}
            />
            <FormControlLabel
                disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                control={
                    <Checkbox
                        checked={stateOfCheckbox.integracion ? false : stateOfCheckbox.autorizacion}
                        onChange={(e) => {
                            if (stateOfCheckbox.integracion && e.target.checked) MessageNotification("Para marcar esta opción deberá desmarcar el campo 'integración'", "error");
                            else{
                                setStateCheckBox({
                                    ...stateOfCheckbox,
                                    autorizacion: e.target.checked,
                                });
                            }
                        }}
                    />
                }
                label='Requiere Autorización'
            />
            <FormControlLabel
                disabled={values.esObraSocialColegio || roleType === TypeRole.Colegio || isView}
                control={
                    <Checkbox
                        checked={stateOfCheckbox.convenio}
                        onChange={(e) => {
                            if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                                MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                                return;
                            }

                            setStateCheckBox({
                                ...stateOfCheckbox,
                                convenio: e.target.checked
                            });

                            if (stateOfCheckbox.integracion){
                                if (e.target.checked === false && roleType === TypeRole.Admin){
                                    MessageNotification("Al desmarcar 'Convenio directo con regional', se pierden los datos de 'Integración'.", "warning");
                                    RestoreIntegrationValue(e);
                                }
                                if (e.target.checked && roleType === TypeRole.Colegio){
                                    MessageNotification("Al marcar 'Convenio directo con regional', se pierden los datos de 'Integración'.", "warning");
                                    RestoreIntegrationValue(e);
                                }
                            }
                        }}
                    />
                }
                label='Convenio Directo con Regional'
            />
            <FormControlLabel
                disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                control={
                    <Checkbox
                        checked={stateOfCheckbox.integracion ? false : stateOfCheckbox.aplicaCoseguro}
                        onChange={(e) => {
                            if ((values.esObraSocialColegio && roleType === TypeRole.Admin)){
                                MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                                return;
                            }
                            if (stateOfCheckbox.integracion && e.target.checked) MessageNotification("Para marcar esta opción deberá desmarcar el campo 'integración'", "error");
                            setStateCheckBox({
                                ...stateOfCheckbox,
                                aplicaCoseguro: e.target.checked,
                            });
                        }}
                    />
                }
                label='Aplica Coseguro'
            />
            <FormControlLabel
                disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                control={
                    <Checkbox
                        checked={stateOfCheckbox.nroSiniestro}
                        onChange={(e) => {
                            if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                                MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                                return;
                            }
                            setStateCheckBox({
                                ...stateOfCheckbox,
                                nroSiniestro: e.target.checked,
                            });
                        }}
                    />
                }
                label='Requiere Número de Siniestro'
            />
            <FormControlLabel
                disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                control={
                    <Checkbox
                        checked={stateOfCheckbox.integracion}
                        onChange={(e) => {
                            if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                                MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                                return;
                            }
                            setValues({...values, cantidadMaximaSesiones: e.target.checked ? 1 : 0, autorizacion: false})
                            setStateCheckBox({
                                ...stateOfCheckbox,
                                integracion: e.target.checked,
                                autorizacion: false,
                                aplicaCoseguro: false
                            });
                        }}
                    />
                }
                label='Integración'
            />
        </Grid.Column>

        <Grid.Column className={customStyles.columnSpace}>
            <TextField
                disabled={TypeRole.Colegio === roleType || isView}
                size='small'
                label='Alias'
                autoComplete="off"
                value={values.alias}
                onChange={(e) => {
                    setValues({
                        ...values,
                        alias: e.target.value === "" ? null : e.target.value
                    });
                }}
                variant="outlined"
                className={`${TypeRole.Colegio === roleType || isView ? "disabled-input": ""}`}
            />
            {stateOfCheckbox.integracion && 
            <>
                <TextField
                    disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin || isView)}
                    size='small'
                    label={`${ socialWorksIds.includes(values.id) ? "Usuario" : "API-KEY" }`}
                    autoComplete="off"
                    value={values.apikey}
                    onChange={(e) => {
                        if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                            MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                            return;
                        }
                        setValues({
                            ...values,
                            apikey: e.target.value === "" ? null : e.target.value
                        });
                    }}
                    variant="outlined"
                />
                <TextField
                    disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                    size='small'
                    label='Clave'
                    autoComplete="off"
                    value={values.clave}
                    onChange={(e) => {
                        if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                            MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                            return;
                        }
                        setValues({
                            ...values,
                            clave: e.target.value === "" ? null : e.target.value
                        });
                    }}
                    variant="outlined"
                    />
                {stateOfCheckbox.requiereToken && values.id === IntegrationSocialWork.swissMedical &&
                    <>
                        <TextField
                            disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                            size='small'
                            label='Correo'
                            autoComplete="off"
                            value={values.email}
                            onChange={(e) => {
                                if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                                    MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                                    return;
                                }
                                setValues({
                                    ...values,
                                    email: e.target.value === "" ? null : e.target.value
                                });
                            }}
                            variant="outlined"
                        />
                        <TextField
                            disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                            size='small'
                            type="number"
                            label='Código Prestador'
                            autoComplete="off"
                            value={values.codigoPrestador}
                            onChange={(e) => {
                                if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                                    MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                                    return;
                                }
                                setValues({
                                    ...values,
                                    codigoPrestador: e.target.value === "" ? null : +e.target.value
                                });
                            }}
                            variant="outlined"
                        />
                        <TextField
                            disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                            size='small'
                            label='Terminal'
                            autoComplete="off"
                            value={values.terminal}
                            onChange={(e) => {
                                if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                                    MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                                    return;
                                }
                                setValues({
                                    ...values,
                                    terminal: e.target.value === "" ? null : e.target.value
                                });
                            }}
                            variant="outlined"
                        />
                    </>
                }
                {values.id === IntegrationSocialWork.prevencionSalud &&
                    <>
                        <TextField
                            disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                            size='small'
                            label='Sitio Emisor'
                            autoComplete="off"
                            value={values.terminal}
                            onChange={(e) => {
                                if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                                    MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                                    return;
                                }
                                setValues({
                                    ...values,
                                    terminal: e.target.value === "" ? null : e.target.value
                                });
                            }}
                            variant="outlined"
                        />
                    </>
                }
                <FormControlLabel
                    disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                    control={
                        <Checkbox
                            checked={stateOfCheckbox.requiereToken}
                            onChange={(e) => {
                                if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                                    MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                                    return;
                                }
                                setStateCheckBox({
                                    ...stateOfCheckbox,
                                    requiereToken: e.target.checked,
                                });
                                setValues({
                                    ...values,
                                    requiereToken: e.target.checked
                                })
                            }}
                        />
                    }
                    label='Requiere Token'
                    />
            </>}
            <TextField
                value={values.cantidadMaximaSesiones}
                disabled={stateOfCheckbox.integracion || (values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                label='Sesiones autorizadas en simultáneo'
                type='number'
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => {
                    if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                        MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                        return;
                    }
                    setValues({
                        ...values,
                        cantidadMaximaSesiones: e.target.value != "" ? parseInt(e.target.value) : null,
                    });
                }}
                />
               {hasManagment &&
                <TextField
                value={values.debitoAutomatico}
                disabled={TypeRole.Colegio === roleType}
                label='Debito Automático'
                type='number'
                InputLabelProps={{shrink: true}}
                InputProps={{
                    inputProps: {max: 100, min: 0},
                    startAdornment: (
                    <InputAdornment position="start">
                        <Icon name="percent" />
                    </InputAdornment>
                    ),
                }}
                onChange={(e) => {
                    setValues({
                        ...values,
                        debitoAutomatico: e.target.value !== "" ? +e.target.value : 0,
                    });
                }}
                />}
            <TextField
                value={values.afiliadoCantidadCaracteresMin}
                disabled={stateOfCheckbox.integracion || (values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                label='Cantidad de caracteres Mínimo para el Nro. de Afiliado'
                type='number'
                inputProps={{ min: 0}}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => {
                    if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                        MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                        return;
                    }
                    setValues({
                        ...values,
                        afiliadoCantidadCaracteresMin: e.target.value != "" ? parseInt(e.target.value) : null,
                    });
                }}
            />
            <TextField
                value={values.afiliadoCantidadCaracteresMax}
                disabled={stateOfCheckbox.integracion || (values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                label='Cantidad de caracteres Máximo para el Nro. de Afiliado'
                type='number'
                inputProps={{ min: 0}}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => {
                    if (values.esObraSocialColegio && roleType === TypeRole.Admin){
                        MessageNotification("No se pueden actualizar campos distintos a 'Alias' si la obra social fue configurada por un colegio.", "error");
                        return;
                    }
                    setValues({
                        ...values,
                        afiliadoCantidadCaracteresMax: e.target.value != "" ? parseInt(e.target.value) : null,
                    });
                }}
            />
        </Grid.Column>
    </GeneralModalCrud>
 )  
}

export default ModalOsForRegional;