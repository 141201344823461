import { InputAdornment, makeStyles } from "@material-ui/core/";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import ReactSelect from 'react-select';
import {
    Grid, Icon
} from "semantic-ui-react";
import { IntegrationSocialWork, StatusCode, TypeRole } from '../../../../enums';
import GeneralModalShow from '../../../utils/GeneralModalShow';
import { _DEFAULT_LOADER_SELECT } from "../defaultValues";
import api from "../../../../api";
import { selectTypeFormat } from "../../../../services/interfaces/utils/IUtilDeclaration";
import { useSelector } from "react-redux";

const objStyle = makeStyles({
    columnSpace: {display: "flex", flexDirection: "column", gap: 18}
});

const ViewConfigSocialWork: React.FC<any> = ({
    isView,
    titleOfModal,
    values,
    roleType,
    openModal,
    errors,
    stateOfCheckbox,
    CloseModal,
}) => {
    const customStyles = objStyle();
    const hasManagment = useSelector<any>(({managment}) => managment.hasManagment);

    return (
    <GeneralModalShow
    title={titleOfModal}
    openModal={openModal}
    HandleClose={CloseModal}
    styles={"contModalGrid cont-default-modal"}
    >
        <Grid.Column className={customStyles.columnSpace}>
            <ReactSelect
                placeholder={`${values.nombre ?? "Obras sociales..."}`}
                options={[]}
                isLoading={false}
                isClearable={true}
                isDisabled={isView}
                className={errors.obraSocialId ? "errorNotificationInput" : ""}
                styles={{
                    control: (s)=>({...s, maxWidth: 290})
                }}
            />
            <FormControlLabel
                disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                control={
                    <Checkbox checked={stateOfCheckbox.integracion ? false : stateOfCheckbox.autorizacion}/>
                }
                label='Requiere Autorización'
            />
            <FormControlLabel
                disabled={values.esObraSocialColegio || roleType === TypeRole.Colegio || isView}
                control={
                    <Checkbox checked={stateOfCheckbox.convenio}/>
                }
                label='Convenio Directo con Regional'
            />
            <FormControlLabel
                disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                control={
                    <Checkbox checked={stateOfCheckbox.integracion ? false : stateOfCheckbox.aplicaCoseguro}/>
                }
                label='Aplica Coseguro'
            />
            <FormControlLabel
                disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                control={
                    <Checkbox checked={stateOfCheckbox.nroSiniestro}/>
                }
                label='Requiere Número de Siniestro'
            />
            <FormControlLabel
                disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                control={
                    <Checkbox
                        checked={stateOfCheckbox.integracion}
                    />
                }
                label='Integración'
            />
        </Grid.Column>

        <Grid.Column className={customStyles.columnSpace}>
            <TextField
                disabled={TypeRole.Colegio === roleType || isView}
                size='small'
                label='Alias'
                autoComplete="off"
                value={values.alias}
                variant="outlined"
                className={`${TypeRole.Colegio === roleType || isView ? "disabled-input": ""}`}
            />
            {stateOfCheckbox.integracion && <>
                <TextField
                    disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin || isView)}
                    size='small'
                    label='API-KEY'
                    autoComplete="off"
                    value={values.apikey}
                    variant="outlined"
                    />
                <TextField
                    disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                    size='small'
                    label='Clave'
                    autoComplete="off"
                    value={values.clave}
                    variant="outlined"
                    />
                {stateOfCheckbox.requiereToken &&
                    values.id === IntegrationSocialWork.swissMedical &&
                <>
                    <TextField
                        disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                        size='small'
                        label='Correo'
                        autoComplete="off"
                        value={values.email}
                        variant="outlined"
                        />

                    <TextField
                        disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                        size='small'
                        type="number"
                        label='Código Prestador'
                        autoComplete="off"
                        value={values.codigoPrestador}
                        variant="outlined"
                        />

                    <TextField
                        disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                        size='small'
                        label='Terminal'
                        autoComplete="off"
                        value={values.terminal}
                        variant="outlined"
                        />

                </>}
                <FormControlLabel
                    disabled={(values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                    control={
                        <Checkbox
                            checked={stateOfCheckbox.requiereToken}
                        />
                    }
                    label='Requiere Token'
                    />
            </>}
            <TextField
                value={values.cantidadMaximaSesiones}
                disabled={stateOfCheckbox.integracion || (values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                label='Sesiones autorizadas en simultáneo'
                type='number'
                InputLabelProps={{
                    shrink: true,
                }}
                />
               {hasManagment &&
                <TextField
                value={values.debitoAutomatico}
                disabled={TypeRole.Colegio === roleType}
                label='Debito Automático'
                type='number'
                InputLabelProps={{shrink: true}}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Icon name="percent" />
                    </InputAdornment>
                    ),
                }}
            />}
            <TextField
                value={values.afiliadoCantidadCaracteresMin}
                disabled={stateOfCheckbox.integracion || (values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                label='Cantidad de caracteres Mínimo para el Nro. de Afiliado'
                type='number'
                inputProps={{ min: 0 }}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                value={values.afiliadoCantidadCaracteresMax}
                disabled={stateOfCheckbox.integracion || (values.esObraSocialColegio && roleType === TypeRole.Admin) || isView}
                label='Cantidad de caracteres Máximo para el Nro. de Afiliado'
                type='number'
                inputProps={{ min: 0 }}
                InputLabelProps={{ shrink: true }}
            />
        </Grid.Column>
    </GeneralModalShow>
    )
}

export default ViewConfigSocialWork;