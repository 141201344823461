import { IntegrationSocialWork, TypeRole, TypeRoleNum, ValidationObjRegex } from "../../../../enums";

function ValidateValues(
    values, 
    rolLogged, 
    setErrorInput, 
    MessageNotification, 
    idProf
){
    const today = new Date();
    let isValidated = true;
    let filteredValues = [];
    const keysToIgnore = [
        values['obraSocialId'] === IntegrationSocialWork.apross || values.obraSocialId === null ? "monto" : "",
        values.obraSocialId === null ? "obraSocialId" : "",
        idProf !== undefined ? "clave" : "",
        values.bancoId === null ? "bancoId" : "",
        values.seguroId === null ? "seguroId" : ""
    ];

    const ignoreKeysForRole = (role) => {
        let keysIgnore = [...keysToIgnore];
        if (role === TypeRole.Admin) {
            keysIgnore = [...keysIgnore, "cuentaId", "colegioId"];
        } else if (role === TypeRole.SuperAdmin) {
            if (TypeRoleNum.Colegio === values.rolId){
                keysIgnore = [...keysIgnore, "obraSocialId", "cuentaId"];
            }
            if (TypeRoleNum.ObraSocial === values.rolId){
                keysIgnore = [...keysIgnore, "cuentaId", "colegioId"];
            }
            if (TypeRoleNum.Professional === values.rolId || TypeRoleNum.Admin === values.rolId){
                keysIgnore = [...keysIgnore, "obraSocialId", "colegioId"];
            }
        }
        return keysIgnore;
    };

    const checkFieldValidity = (errorMessage, errorKey) => {
        MessageNotification(errorMessage, "error");
        setErrorInput({[errorKey]: true });
        isValidated = false;
    };

    const checkAgeValidity = () => {
        const age = Math.floor((today.getTime() - values.fechaNacimiento) / 31557600000);
        if (age < 18) {
            checkFieldValidity("Fecha incorrecta. El Profesional debe ser mayor a 18 años", "fechaNacimiento");
        }
    };

    const checkCUITValidity = () => {
        if (isNaN(values['cuit']) || values['cuit'].toString().length !== 11) {
            checkFieldValidity("El campo 'CUIT' debe tener 11 dígitos y ser un número.", "cuit");
        }
    };

    const checkCBUValidity = () => {
        if (!isNaN(values['cbu']) && values['cbu'].toString().length !== 22) {
            checkFieldValidity("El campo 'CBU' debe tener 22 dígitos.", "cbu");
        }
    };

    const checkEmailValidity = () => {
        if (!ValidationObjRegex.emailValidation.test(values['email'])) {
            checkFieldValidity("Formato de correo inválido.", "email");
        }
    };

    const role = rolLogged === TypeRole.Admin ? TypeRole.Admin : TypeRole.SuperAdmin;
    const keysIgnore = ignoreKeysForRole(role);

    filteredValues = Object.keys(values).filter(key => !keysIgnore.includes(key) && (values[key] === null || values[key] === ""));

    if (filteredValues.length !== 0) {
        let objKeyInput = {};
        filteredValues.forEach(key => {
            objKeyInput[key] = true;
        });
        MessageNotification("Todos los campos son obligatorios.", "error");
        setErrorInput(objKeyInput);
        isValidated = false;
    } else {
        checkAgeValidity();
        checkCUITValidity();
        checkCBUValidity();
        checkEmailValidity();
    }

    return isValidated;
}

export {ValidateValues};