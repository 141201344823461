import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent, Dimmer, Loader } from 'semantic-ui-react';
import { IDescountParamsByOs } from '../../../api/Interfaces/configurations/IDescountOs';
import { IntegrationSocialWork, StatusCode } from '../../../enums';
import { IStateModalDescount, IUserCreateOrUpdate } from '../../../services/interfaces/Configurations/IUserCreateOrUpdate';
import { GetDescountParam } from '../../../services/services/ConfigurationRegional';
import { CreateProfesional, GetProfesionalById, UpdateProfesional } from '../../../services/services/Users';
import GeneralModalTabs from '../../utils/GeneralModalTabs';
import FamilyProfessional from './PanelsSection/FamilyProfessional';
import InformationProfessional from './PanelsSection/InformationProfessional';
import { _DEFAULT_VALUES } from './defaultValues';
import { ValidateValues } from './utilityFunctions/ValidateValues';

const UsersCreateOrUpdate: React.FC<IUserCreateOrUpdate> = ({setDataToast})=>{
    const {idProf} = useParams<any>();
    const history = useHistory<any>();
    const [rolLogged, setRolLogged] = React.useState<any>(JSON.parse(localStorage.getItem("TypeRole") ?? ""));
    const [dataProfesional, setDataProfesional] = React.useState<any>(_DEFAULT_VALUES._DATA_PROFESIONAL);
    const [errorInput, setErrorInput] = React.useState<any>({});
    const [loadingActive, setLoadingActive] = React.useState(false);
    const [panelsSection, setPanelsSection] = React.useState(["Datos del usuario"]);
    const [openModalInfo, setOpenModalInfo] = React.useState<IStateModalDescount>(_DEFAULT_VALUES._OPEN_MODAL_INFO_DESCOUNT);
    const [amountDetail, setAmountDetail] = React.useState<IDescountParamsByOs>(_DEFAULT_VALUES._DESCOUNT_AMOUNT);

    React.useEffect(()=>{
        if (idProf !== undefined) {
            GetProfessionalById();
        }
    },[idProf]);

    async function GetProfessionalById(){
        try{
            setLoadingActive(true);
            const {data, status} = await GetProfesionalById(+idProf);
            if (status === StatusCode.Ok){

                setPanelsSection([...panelsSection, "Familiares"]);
                const dataProfesional = {
                    id: data.results.id,
                    email: data.results.email,
                    nombre: data.results.nombre,
                    apellido: data.results.apellido,
                    rolId: data.results.rol !== null ? data.results.rol.id : null,
                    matricula: data.results.matricula,
                    obraSocialId: data.results.obraSocial !== null ? data.results.obraSocial.id : null,
                    cuit: data.results.cuit,
                    condicionIVAId: data.results.condicionIVA?.id,
                    sexo: data.results.sexo,
                    cuentaId: data.results.cuenta !== null ? data.results.cuenta.id : null,
                    colegioId: data.results.colegio !== null ? data.results.colegio.id : null,
                    administradoPorRegional: data.results.administradoPorRegional,
                    trabajaConObraSocial: data.results.trabajaConObraSocial,
                    noSocio: data.results.noSocio,
                    fechaNacimiento: data.results.fechaNacimiento ? new Date(data.results.fechaNacimiento) : null,
                    cbu: data.results.cbu,
                    bancoId: data.results.banco !== null ? data.results.banco.id : null,
                    monto: data.results.monto,
                    seguroId: data.results.seguro !== null && data.results.seguro !== undefined ? data.results.seguro.id : null
                }
                setDataProfesional(dataProfesional);

                if (data.results.obraSocial?.id === IntegrationSocialWork.apross){
                    GetDescountAmountByOs(dataProfesional);
                }
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    async function GetDescountAmountByOs(values){
        try{
            const {data, status} = await GetDescountParam(+idProf);
            if (status === StatusCode.Ok){
                setAmountDetail(data.results);
                setOpenModalInfo({modal: false, viewInfo: true});
                setDataProfesional({...values, monto: data.results.total});
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores, "error");
        }
    }
    
    async function SendValues(){
        try{
            if (ValidateValues(dataProfesional, 
                rolLogged, 
                setErrorInput,
                MessageNotification, 
                idProf
                )){
                 const paramRequest = {
                     ...dataProfesional, 
                     cuit: +dataProfesional.cuit, 
                     matricula: +dataProfesional.matricula,
                     monto: dataProfesional.obraSocialId === IntegrationSocialWork.apross ? +amountDetail.total : +dataProfesional.monto
                 }
                 setLoadingActive(true);
                if (idProf === undefined){
                    const {data, status} = await CreateProfesional(paramRequest);
                    if (status === StatusCode.Ok){
                        MessageNotification("Profesional creado correctamente.", "success");
                        const aditionalData = {isRedirectionFromCreate: true}
                        setErrorInput({});
                        history.push({pathname: `/configuraciones/usuarios/${data.results}`, state: aditionalData});
                    }
                }else{
                    const {status} = await UpdateProfesional(+idProf, paramRequest);
                    if (status === StatusCode.Ok){
                        MessageNotification("Profesional editado correctamente.", "success");
                        setErrorInput({});
                        history.push('/configuraciones/usuarios');
                    }
                }  
            }
     }catch(err: any){
        MessageNotification(err.message ?? err.response.data.errores, "error");
     }finally{
        setLoadingActive(false);
     }
    }
    function MessageNotification(message, type){
        setDataToast({
            active: true,
            message: message || "Acaba de ocurrir un error",
            type: type,
        });
    }

    return (
        <>
            {loadingActive &&
                <Dimmer className='loader-fixed' active inverted>
                    <Loader size='big'>Cargando..</Loader>
                </Dimmer>
            }
            <Grid>
                <Card className='color-border-dash'>
                    <CardContent className='center-responsive-filters'>
                        <Grid className='header-section'>
                            <div className="titleAndVideo">
                                <span style={{ fontSize: 27, textAlign: "left", lineHeight: "1.03" }}>Datos del Usuario</span>
                            </div>
                            <div className="contBtnHeaderSection">
                                <Button onClick={()=>history.push("/configuraciones/usuarios")}>Cancelar</Button>
                                <Button onClick={SendValues} color='green'>Aceptar</Button> 
                            </div>
                        </Grid>
                    </CardContent>
                </Card>
                {!loadingActive &&
                <GeneralModalTabs
                viewPanelDefault={history.location.state?.isRedirectionFromCreate ? panelsSection.findIndex(title => title === "Familiares") : 0}
                arrPanels={[
                    <InformationProfessional 
                    dataProfesional={dataProfesional}
                    openModalInfo={openModalInfo}
                    amountDetail={amountDetail}
                    errorInput={errorInput}
                    MessageNotification={MessageNotification}
                    setOpenModalInfo={setOpenModalInfo}
                    setDataProfesional={setDataProfesional}
                    GetDescountAmountByOs={GetDescountAmountByOs}
                    />, 
                    idProf !== undefined ? <FamilyProfessional MessageNotification={MessageNotification}/> : <></>
                ]}
                arrTittleOfPanels={panelsSection}
                />}
            </Grid>
        </>
    );
}

export default UsersCreateOrUpdate;