import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { IReceiptFilterRequest, IReceiptResponse } from '../../api/Interfaces/managment/IReceipt';
import { StatusCode } from '../../enums';
import { IFilterObj, IModals } from '../../services/interfaces/Managment/IReceipts';
import { DeleteReceipt, GetPrintReceipt, GetReceiptList } from '../../services/services/Receipts';
import ButtonBox from '../utils/ButtonBox';
import GeneralList from '../utils/GeneralList';
import GeneralModalDelete from '../utils/GeneralModalDelete';
import { _DEFAULT_VALUES_RECEIPTS } from './defaultValues';
import NewModalReceipts from './modals/Receipts/NewModalReceipts';
import ViewModalReceipts from "./modals/Receipts/ViewModalReceipts";
import GeneralModalCrud from "../utils/GeneralModalCrud";
import { useSelector } from "react-redux";

const _LOAD_DEFAULT_PAGES = 20;

const Receipts: React.FC<any> = ({setDataToast}) => {
    const hasManagment = useSelector<any>(({managment}) => managment.hasManagment);
    const [dataToRender, setDataToRender] = React.useState<IReceiptResponse[]>([]);
    const [loaderActive, setLoaderActive] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [loadForPage, setLoadForPage] = React.useState(1);
    const [modals, setModals] = React.useState<IModals>(_DEFAULT_VALUES_RECEIPTS._MODALS);
    const [filterObj, setFilterObj] = React.useState<IFilterObj>(_DEFAULT_VALUES_RECEIPTS._FILTER_OBJ);
    const [reactivateReceipt, setReactivateReceipt] = React.useState(false);

    React.useEffect(()=>{
        (hasManagment && LoaderDataForTable());
    },[]);

    //#region COMPONENTS
    const ButtonNew = () =>(
        <Button
            className='btn-new-filter'
            onClick={(e)=>HandleChangeStateOfModals(e)}
            icon
            name="new"
            labelPosition='left'
            >
            <Icon name='plus' />
            Nuevo
        </Button>
    );
    const FilterComponents = [
    (key)=> (
    <TextField
    key={key}
    className='filter-input'
    style={{margin: "15px 0"}}
    value={filterObj.numberReceipt}
    label='Número'
    type='number'
    variant='outlined'
    size='small'
    InputLabelProps={{shrink: true}}
    InputProps={{
        inputProps: {max: 9999999, min: 0}
    }}
    onChange={(e)=>setFilterObj({...filterObj, numberReceipt: e.target.value === "" ? null : +e.target.value})}
    />
    ),
    (key)=>(
    <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
        <KeyboardDatePicker
        autoOk
        key={key}
        className="size-date-picke-filter filter-input"
        clearable
        style={{ margin: 8 }}
        value={filterObj.dateFrom}
        placeholder="Fecha Desde"
        onChange={(e)=>setFilterObj({...filterObj, dateFrom: e})}
        format="dd/MM/yyyy"
        margin="normal"
        inputVariant="outlined"
        />
    </MuiPickersUtilsProvider>
    ),
    (key)=>(
    <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
        <KeyboardDatePicker
        autoOk
        key={key}
        className="size-date-picke-filter filter-input"
        clearable
        style={{ margin: 8 }}
        value={filterObj.dateTo}
        placeholder="Fecha Hasta"
        onChange={(e)=>setFilterObj({...filterObj, dateTo: e})}
        format="dd/MM/yyyy"
        margin="normal"
        inputVariant="outlined"
        />
    </MuiPickersUtilsProvider>
    ),
    (key)=>(
    <FormControlLabel
    control={
        <Checkbox
        key={key}
        checked={filterObj.deleted}
        onChange={(e)=>setFilterObj({...filterObj, deleted: e.target.checked})}
        />
    }
    label="Dados de baja"
    />
    )
    ]
    //#endregion

    //#region GET / ELIMINAR / IMPRIMIR
    async function LoaderDataForTable(pageSelected: number = 1){
        try{
            if (ValidateFilterObj()){
                setLoaderActive(true);
                const params: IReceiptFilterRequest = {
                    cantidad: _LOAD_DEFAULT_PAGES,
                    eliminado: filterObj.deleted,
                    pagina: pageSelected,
                    fechaDesde: filterObj.dateFrom,
                    fechaHasta: filterObj.dateTo,
                    numero: filterObj.numberReceipt
                }
                const {data, status} = await GetReceiptList(params);
                if (status === StatusCode.Ok){
                    setDataToRender(data.results);
                    setLoadForPage(data.quantity ?? 1);
    
                    if (filterObj.deleted) setReactivateReceipt(true);
                    else setReactivateReceipt(false);
                }
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores,"error");
        }finally{
            setLoaderActive(false);
        }
    }
    async function HandleDeleteReceipts(){
        try{
            setLoaderActive(true);
            const {data, status} = await DeleteReceipt(modals.delete.data.id as number);
            if (status === StatusCode.Ok){
                HandleCloseModals();
                MessageNotification("Recibo eliminado correctamente","success");
                LoaderDataForTable();
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores,"error");
        }finally{
            setLoaderActive(false);
        }
    }
    function HandlePrintReceipt(receiptSelected: IReceiptResponse){
        setLoaderActive(true);
        GetPrintReceipt(receiptSelected.id)
        .then(data => {
            let extension = "pdf";
            let tempFileName = "Recibo";
            let fileName = `${tempFileName}.${extension}`;
            let blob = new Blob([data.data.results], {
                type: "application/pdf",
            });
            let a = document.createElement("a");
            document.body.appendChild(a);
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(err => MessageNotification(err.response.data.errores,"error"))
        .finally(()=>{
            setLoaderActive(false);
        });
    }
    //#endregion
    
    //#region UTILS
    function ValidateFilterObj(){
        let isValidated = true;

        if (filterObj.dateFrom !== null && filterObj.dateTo == null || filterObj.dateTo !== null && filterObj.dateFrom == null){
            MessageNotification("Fecha desde y Fecha Hasta, deben estar completos.", "error");
            isValidated = false;
        }
        if (filterObj.numberReceipt !== null && (filterObj.numberReceipt < 0 || +filterObj.numberReceipt > 9999999)){
            MessageNotification("El número del recibo debe ser mayor a cero y contener 7 dígitos.", "error");
            isValidated = false;
        }

        return isValidated;
    }
    function HandleChangePage(e, pageSelected){
        setPage(pageSelected);
        LoaderDataForTable(pageSelected);
    }
    function HandleCloseModals(){
        setModals(_DEFAULT_VALUES_RECEIPTS._MODALS);
    }
    function HandleChangeStateOfModals(modalSelected: any, aditionalData = null){
        setModals({
            ...modals,
            [modalSelected.currentTarget.name]: {show: true, data: aditionalData}
        });
    }
    function MessageNotification(message: string, type: string){
        setDataToast({
            active: true,
            message,
            type
        });
    }
    //#endregion

    return (
        <>
        <GeneralList
        titleOfSection='Recibos'
        isLoaderActive={loaderActive}
        initialPage={page}
        numPagesToRender={loadForPage}
        dataToRender={dataToRender}
        TestFilterComponents={FilterComponents}
        HandleSearch={LoaderDataForTable}
        ButtonNew={ButtonNew}
        onChangePage={HandleChangePage}
        >
            <Table aria-label='spanning table'>
                <TableHead>
                    <TableRow>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Número</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Fecha</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Entidad</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Nombre</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Monto</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Interés</b>
                        </TableCell>
                        {!reactivateReceipt && 
                        <TableCell className='letter-title-table c-text-center'>
                            <b>Opciones</b>
                        </TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataToRender.map((item: any) => (
                        <TableRow key={item.id}>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {`${item.numeroSucursal}-${item.numero}`}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {new Date(item.fecha).toLocaleDateString()}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.profesional !== null ? "Profesional" :
                                item.obraSocialCuenta !== null ? "Obra Social" : "Libre"}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.profesional !==  null ? item.profesional.nombre :
                                item.obraSocialCuenta !== null ? item.obraSocialCuenta.nombre : item.nombreLibre}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                $ {Intl.NumberFormat().format(item.monto)}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                $ {Intl.NumberFormat().format(item.interes) ?? 0}
                            </TableCell>
                            {!reactivateReceipt &&
                            <TableCell className='p-cell'>
                                <Button.Group>
                                    <ButtonBox buttonToShow='view' name="view" HandleOnClik={(e)=>HandleChangeStateOfModals(e, item)}/>
                                    <ButtonBox buttonToShow='print' name="print" HandleOnClik={()=>HandlePrintReceipt(item)}/>
                                    <ButtonBox buttonToShow='delete' name="delete" HandleOnClik={(e)=>HandleChangeStateOfModals(e, item)}/>
                                </Button.Group>
                            </TableCell>}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </GeneralList>

        {modals.new.show &&
        <NewModalReceipts
        dataToRender={dataToRender}
        isView={false} 
        modalSelected={modals.new}
        titleOfModal="Nuevo"
        ReloadData={LoaderDataForTable}
        HandleCloseModals={HandleCloseModals}
        MessageNotification={MessageNotification}
        />}

        {modals.view.show &&
        <ViewModalReceipts
        titleOfModal="Ver Recibo"
        isView={true} 
        modalSelected={modals.view}
        HandleCloseModals={HandleCloseModals}
        MessageNotification={MessageNotification}
        />}

        {modals.delete.show && 
        <GeneralModalDelete 
        contentText={`¿Está seguro de eliminar el recibo N°: ${modals.delete.data.numero}?`}
        openModal={modals.delete.show}
        HandleClose={HandleCloseModals}
        HandleSubmit={HandleDeleteReceipts}
        />}
        </>
    );
}

export default Receipts;