import { FormControl, Paper, TextField, makeStyles } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Button,
	Container,
	Dropdown,
	Grid,
	Icon,
	Menu
} from "semantic-ui-react";
import api from "../../api";
import { StatusCode } from "../../enums";


const useStyles = makeStyles((theme) => ({
    columnInfo:{
      display: "flex",
      gap: 20,
      flexDirection: "column",
      padding: 10
    },
	btnActions:{
		position: "absolute",
		bottom: 0
	}
  }));

const _DEFAULT_VALUES = {
    _USER_INFO: {
        email: null,
        name: null,
        lastName: null,
        rol: {id: null, nombre: null},
        enrollment: null,
        cuit: null,
        sex: null,
        cbu: null,
        bornDate: null,
        workForSocialWork: false,
        managmentByRegional: false,
        noPartner: false
    }
}

export default function MyAccount({ setDataToast }) {
  const history = useHistory();
  const classes = useStyles();

  const [userInfo, setUserInfo] = useState(_DEFAULT_VALUES._USER_INFO);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [newValue, setNewValue] = useState({
    password: "",
    showPassword: false,
  });
  const [newValueConfirm, setNewValueConfirm] = useState({
    password: "",
    showPassword: false,
  });

	useEffect(()=>{
		GetUserData();
	},[]);

	async function GetUserData(){
		try {
		const { status, data } = await api.getDataCurrentUser();
		if (status === StatusCode.Ok) {
			setUserInfo({
				email: data.email,
				name: data.nombre,
				lastName: data.apellido,
				rol: data.rol,
				enrollment: data.matricula,
				cuit: data.cuit,
				sex: data.sexo,
				cbu: data.cbu,
				bornDate: data.fechaNacimiento,
				workForSocialWork: data.trabajaConObraSocial,
				managmentByRegional: data.administradoPorRegional,
				noPartner: data.noSocio
			});
		}
		} catch (err) {
		console.log(err);
		}
	}

  const shortError = (message) => {
    setDataToast({
      active: true,
      message: message,
      type: "error",
    });
  };
	function handleClickBack() {
		history.goBack();
	}
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};
	const handleClickShowPasswordNew = () => {
		setNewValue({ ...newValue, showPassword: !newValue.showPassword });
	};
	const handleClickShowPasswordNewConfirm = () => {
		setNewValueConfirm({
			...newValueConfirm,
			showPassword: !newValueConfirm.showPassword,
		});
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	const handleChangePasswordNewConfirm = (prop) => (event) => {
		setNewValueConfirm({ ...newValueConfirm, [prop]: event.target.value });
	};
	const handleChangePasswordNew = (prop) => (event) => {
		setNewValue({ ...newValue, [prop]: event.target.value });
	};
	const handleChangeCurrentPassword = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};
	const handleClickConfirmPassword = () => {
		if (!values.password) {
			shortError('No ingreso la clave actual');
		}
		if (!newValue.password) {
			shortError('No ingreso la nueva clave');
		} else if (!newValueConfirm.password) {
			shortError('No ingreso la confirmacion de la clave');
		} else if (newValue.password != newValueConfirm.password) {
			shortError('Las claves no coinciden');
		}
		if (
			values.password &&
			newValue.password &&
			newValueConfirm.password &&
			newValue.password == newValueConfirm.password
		) {
			let body = {
				actualPassword: values.password,
				newPassword: newValue.password,
				confirmNewPassword: newValueConfirm.password,
			};
			api
				.patchUpdatePassword(body)
				.then((resp) => {
					setDataToast({
						active: true,
						message: 'La actualizacion de la clave se realizó correctamente',
						type: 'success',
					});
					setValues({ password: '', showPassword: false });
					setNewValue({ password: '', showPassword: false });
					setNewValueConfirm({ password: '', showPassword: false });
					history.push('/perfil');
				})
				.catch((err) => {
					shortError(
						err?.response?.data?.errores || 'Acaba de ocurrir un error'
					);
				});
		}
	};
	const triggerDropdownUser = (
		<span style={{ color: 'black' }}>
			<span style={{marginRight: 5}}>{'Mi Perfil'}</span>
			<Icon name='user circle' size='large' />
		</span>
	);
	const DropdownUser = () => {
		return <Dropdown style={{ marginTop: 3 }} trigger={triggerDropdownUser}/>;
	};

	return (
		<Container style={{position:"relative"}}>
			<Menu fixed='top' inverted className='background-header-authenticated'>
				<Menu.Item position='right'>
					<span style={{ color: 'black' }} className='c-mr-40'></span>
					<DropdownUser />
				</Menu.Item>
			</Menu>
			<Grid columns={2} className="contProfileData">
				<Grid.Column className="contProfileData-block">
					<Paper className='color-border-dash' elevation={20}>
						<Grid.Row className=' c-text-center c-p-20'>
							<p style={{ fontSize: 27, textAlign: "center" }}>Información de la cuenta</p>
							<Grid.Column id='column-info-account' className={`${classes.columnInfo} column-info-account`}>
								<TextField
									id='outlined-uncontrolled'
									label='Nombre'
									value={`${userInfo.name}`}
									disabled={true}
									variant='outlined'
								/>
								<TextField
									id='outlined-uncontrolled'
									label='Apellido'
									value={`${userInfo.lastName}`}
									disabled={true}
									variant='outlined'
								/>
								<TextField
									id='outlined-uncontrolled'
									label='Tipo Rol'
									value={`${userInfo.rol.nombre}`}
									disabled={true}
									variant='outlined'
								/>
								<TextField
									id='outlined-uncontrolled'
									label='Matricula'
									value={`${userInfo.enrollment}`}
									disabled={true}
									variant='outlined'
								/>
								<TextField
									id='outlined-uncontrolled'
									label='Correo'
									multiline
									rowsMax={2}
									value={`${userInfo.email}`}
									disabled={true}
									variant='outlined'										
								/>
								<TextField
									id='outlined-uncontrolled'
									label='CUIT'
									value={`${userInfo.cuit}`}
									disabled={true}
									variant='outlined'
								/>
								<TextField
									id='outlined-uncontrolled'
									label='CBU'
									value={`${userInfo.cbu ?? "No Asignado"}`}
									disabled={true}
									variant='outlined'
								/> 
								<TextField
									id='outlined-uncontrolled'
									label='Fecha Nacimiento'
									value={`${userInfo.bornDate !== null ? new Date(userInfo.bornDate).toLocaleDateString() : "No Asignada"}`}
									disabled={true}
									variant='outlined'
								/>
								<TextField
									id='outlined-uncontrolled'
									label='Sexo'
									value={`${userInfo.sex}`}
									disabled={true}
									variant='outlined'
								/>
								<TextField
									id='outlined-uncontrolled'
									label='Trabaja con Obra Social'
									value={`${userInfo.workForSocialWork ? "Si trabaja" : "No trabaja"}`}
									disabled={true}
									variant='outlined'
								/>
								<TextField
									id='outlined-uncontrolled'
									label='Administrado por Regional'
									value={`${userInfo.managmentByRegional ? "Si Administrado" : "No Administrado"}`}
									disabled={true}
									variant='outlined'
								/>
								<TextField
									id='outlined-uncontrolled'
									label='Socio'
									value={`${userInfo.noPartner ? "Es Socio" : "No Socio"}`}
									disabled={true}
									variant='outlined'
								/>
							</Grid.Column>
						</Grid.Row>
					</Paper>
				</Grid.Column>
				<Grid.Column className="contProfileData-block">
					<Paper className='color-border-dash' elevation={10} style={{position:"relative", height: "100%"}}>
						<Grid.Row className='c-text-center c-p-20'>
							<p style={{ fontSize: 27, textAlign: "center" }}>Actualización de clave</p>
							<Grid.Column id='column-info-account' >
								<Grid.Row className='c-mt-32 c-text-center'>
									<FormControl style={{width: "100%"}}>
										<TextField
											variant="outlined"
											label='Clave Actual'
											id='current-Pass'
											type={values.showPassword ? 'text' : 'password'}
											value={values.password}
											onChange={handleChangeCurrentPassword('password')}
											InputProps={{
												style:{
													padding: 25
												},
												endAdornment: (
													<InputAdornment position='end'>
													<IconButton
														aria-label='toggle password visibility'
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														edge='end'
													>
														{values.showPassword ? (
															<Visibility />
														) : (
															<VisibilityOff />
														)}
													</IconButton>
												</InputAdornment>
												),
												
											}}
											
											labelWidth={85}
										/>
									</FormControl>
								</Grid.Row>
								<Grid.Row className='c-mt-32 c-text-center'>
									<FormControl style={{width: "100%"}}>
										<TextField
											label='Nueva Clave'
											variant="outlined"
											id='newPass'
											type={newValue.showPassword ? 'text' : 'password'}
											value={newValue.password}
											onChange={handleChangePasswordNew('password')}
											InputProps={{
												style:{
													padding: 25
												},
												endAdornment:
													(<InputAdornment position='end'>
														<IconButton
															aria-label='toggle password visibility'
															onClick={handleClickShowPasswordNew}
															onMouseDown={handleMouseDownPassword}
															edge='end'
														>
															{newValue.showPassword ? (
																<Visibility />
															) : (
																<VisibilityOff />
															)}
														</IconButton>
													</InputAdornment>)	
											}}
											labelWidth={85}
										/>
									</FormControl>
								</Grid.Row>
								<Grid.Row className='c-mt-32 c-text-center'>
									<FormControl style={{width: "100%"}}>				
										<TextField
											label='Confirmar clave'
											variant="outlined"
											style={{ marginBottom: 167 }}
											id='ConfirmPass'
											type={newValueConfirm.showPassword ? 'text' : 'password'}
											value={newValueConfirm.password}
											onChange={handleChangePasswordNewConfirm('password')}
											InputProps={{
												style:{
													padding: 25
												},
												endAdornment:
												(<InputAdornment position='end'>
													<IconButton
														aria-label='toggle password visibility'
														onClick={handleClickShowPasswordNewConfirm}
														onMouseDown={handleMouseDownPassword}
														edge='end'
													>
														{newValueConfirm.showPassword ? (
															<Visibility />
														) : (
															<VisibilityOff />
														)}
													</IconButton>
												</InputAdornment>)
											}}
											
											labelWidth={108}
										/>
									</FormControl>
								</Grid.Row>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className={classes.btnActions}>
							<Button className="btn-back-filter" onClick={handleClickBack}>
								Volver
							</Button>
							<Button
								color='green'
								onClick={handleClickConfirmPassword}
								icon
								size='small'
								style={{
									width: 150,
									marginLeft: 10,
								}}
								labelPosition='left'
							>
								<Icon name='key' />
								Confirmar
							</Button>
						</Grid.Row>
					</Paper>
				</Grid.Column>
			</Grid>
		</Container>
	);
}
