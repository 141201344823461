import DateFnsUtils from "@date-io/date-fns";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { es } from "date-fns/locale";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { Button, Card, Dimmer, Grid, Icon, Loader } from "semantic-ui-react";
import api from "../../api";
import { Options, TypeRole, StatusDocuments } from "../../enums";
import { setFilters } from "../../redux/dashboard";
import InputFormLabel from "../common/InputFormLabel";
import "./Dashboard.css";
import DashboardList from "./DashboardList";
import PopUpNotification from "../utils/popUpNotification/PopUpNotification";

const SelectFilter = ({
	label,
	name,
	menuItemList,
	control,
	filtersLoading,
}) => (
		<Controller
			className="align-select width-input-filter-dash filter-input"
			styles={{
				// Fixes the overlapping problem of the component
				menu: (provided) => ({ ...provided, zIndex: 9999 }),
			}}
			as={Select}
			Label={label}
			placeholder={label}
			noOptionsMessage={() => "No hay registros"}
			getOptionLabel={(option) => option.name}
			getOptionValue={(option) => option.id}
			isLoading={filtersLoading}
			isClearable={true}
			isSearchable={true}
			options={menuItemList}
			theme={(theme) => ({
				...theme,
				colors: {
					...theme.colors,
					primary: "#6a5ef3",
				},
			})}
			control={control}
			name={name}
			id={name}
		/>
	);

export default function Dashboard({ dataToast, setDataToast, title, devolutionSection=false }) {
	const dispatch = useDispatch();
	const dashboardRedux = useSelector((state) => state.dashboard);
	const [dataTable, setDataTable] = useState([]);
	const [quantityData, setQuantityData] = useState(0);
	const [loadingActive, setLoadingActive] = useState();
	const [dateToday, setDateToday] = useState();
	const [socialWorks, setSocialWorks] = useState([]);
	const [status, setStatus] = useState([]);
	const [filtersLoading, setFilterLoading] = useState(false);
	const [bodyFilter, setBodyFilter] = useState();
	const [alertDelete, setAlertDelete] = useState(false);
	const [alertEditEvent, setAlertEditEvent] = useState(false);
	const [dataEditEvent, setDataEditEvent] = useState({
		id: null,
		fecha: null,
		sesiones: [],
		idObraSocial: null,
		fechaPedidoMedico: null,
		coseguro: null,
		numeroAfiliado: null,
		numeroDocumentoAfiliado: null,
		tipoDeSesiones: null,
		codigoPrestacion: null,
		cantidadPrestaciones: null,
		numeroAutorizacion: null,
		requiereToken: false,
		integracion: false
	});
	const [dataSweetAlert, setDataSweetAlert] = useState({});
	const loadForPage = 20;
	const [dataPagination, setDataPagination] = useState({
		offset: 1,
		limit: 21,
	});
	const { register, control, getValues, errors, setValue } = useForm({
		defaultValues: {
			patient: '',
			numberAuth: '',
		},
	});
	const [pageTable, setPageTable] = useState(1);
	const [dateFilter, setDateFilter] = useState({
		dateFirstSessionFrom: null,
		dateFirstSessionTo: null,
	});
	const history = useHistory();
	const [hasReturns, setHasReturns] = useState({
		total: 0,
		show: false,
		section: false
	});
	const [isView, setView] = useState(false);
	const [isCreate, setCreate] = useState(false);
	const [isEdit, setEdit] = useState(false);
	const [isDelete, setDelete] = useState(false);
	const [isExport, setExport] = useState(false);
	const [isProfessional, setProfessional] = useState(false);
	const [isAdmin, setAdmin] = useState(false);
	const [isSuperAdmin, setSuperAdmin] = useState(false);

	useEffect(() => {
		let TypeRolUser = JSON.parse(localStorage.getItem('TypeRole'));
		if (TypeRolUser == TypeRole.Professional) {
			setProfessional(true);
			if(devolutionSection){		
				setHasReturns({
					hasReturns,
					show: false,
					section: true
				});
			}
		} if (TypeRolUser == TypeRole.Admin) {
			setAdmin(true);
		}
		if (TypeRolUser == TypeRole.SuperAdmin) {
			setSuperAdmin(true);
		}

		let options = JSON.parse(localStorage.getItem('optionsPatient'));

		options.forEach((element) => {
			if (element == Options.isView) {
				setView(true);
			} else if (
				element == Options.isCreate &&
				TypeRolUser != TypeRole.SuperAdmin
			) {
				setCreate(true);
			} else if (
				element == Options.isEdit &&
				TypeRolUser != TypeRole.SuperAdmin
			) {
				setEdit(true);
			} else if (element == Options.isDelete) {
				setDelete(true);
			} else if (element == Options.isExport) {
				setExport(true);
			}
		});
		setFilterLoading(true);
		applyDataFilters();
		options = null;
	}, []);

	const applyDataFilters = () => {
		var dataFilters = {
			auth: dashboardRedux.filters.auth || '',
			dateFrom: dashboardRedux.filters.dateFrom || '',
			dateTo: dashboardRedux.filters.dateTo || '',
			page: dashboardRedux.filters.page || '',
			patient: dashboardRedux.filters.patient || '',
			quantity: dashboardRedux.filters.quantity || '',
			socialWork: dashboardRedux.filters.socialWork || '',
			status: dashboardRedux.filters.status || ''
		};
		if (dataFilters) {
			setValue('socialWork', dataFilters.socialWork);
			setValue('status', dataFilters.status);
			setValue('numberAuth', dataFilters.auth);
			setValue('patient', dataFilters.patient);
			setDateFilter({
				dateFirstSessionFrom: dataFilters.dateFrom || null,
				dateFirstSessionTo: dataFilters.dateTo || null,
			});
		}
	};

	const hasAnyReturns = ()=>{
		let filter = {
			page: pageTable,
			quantity: loadForPage,
			pagination: true,
			orderby: '-FirstSessionDate',
		};
		api.getReturns(filter)
		.then(resp=>{
			if (resp.data.resultados.length > 0){
				setHasReturns({
					...hasReturns,
					total: resp.data.resultados.length,
					show: true
				});
			}
		})
		.catch(err=>{
			setDataToast({
					active: true,
					message: 'Acaba de ocurrir un error',
					type: 'error',
				});
		})
	}
	const actionConfirmDelete = () => {
			api
			.deleteDocument(dataSweetAlert.id)
			.then((resp) => {
				setAlertDelete(false);
				reloadData();
				setDataToast({
					active: true,
					message: 'Dado de baja con éxito',
					type: 'success',
				});
			})
			.catch((err) => {
				setDataToast({
					active: true,
					message: err.response.data.errores ?? 'Acaba de ocurrir un error',
					type: 'error',
				});
			});
		
	};
	const handleClickNew = () => {
		history.push('/paciente/crear');
		localStorage.setItem('viewListSession', true);
		localStorage.setItem('viewSession', true);
		localStorage.setItem('calculateSessions', false);
	};

	const reloadData = () => {
		let filtersLoad = bodyFilter || dashboardRedux.filters;
		loadData(filtersLoad);
	};

	useEffect(() => {
		var dataFilters = dashboardRedux.filters;
		let objFitlerSave = {
			socialWork: dataFilters.socialWork,
			status: dataFilters.status,
			auth: dataFilters.auth,
			patient: dataFilters.patient,
			dateFirstSessionFrom: dataFilters.dateFirstSessionFrom,
			dateFirstSessionTo: dataFilters.dateFirstSessionTo,
			page: pageTable,
			quantity: loadForPage,
			pagination: true,
			orderby: '-FirstSessionDate',
		};
		dispatch(setFilters(objFitlerSave));
		loadData(objFitlerSave);
	}, [pageTable]);

	const handleClickSearch = () => {
		let values = getValues();
		let date = dateFilter;
		let filters = {};
		if (values.socialWork) {
			filters.socialWork = values.socialWork;
		}
		if(values.status) { 
			filters.status = values.status;
		}
		if (values.numberAuth) {
			filters.auth = values.numberAuth;
		}
		if (values.patient) {
			filters.patient = values.patient;
		}
		if (values.professional) {
			filters.professional = values.professional;
		}
		if (date.dateFirstSessionFrom) {
			filters.dateFirstSessionFrom = moment(date.dateFirstSessionFrom).format();
		}
		if (date.dateFirstSessionTo) {
			filters.dateFirstSessionTo = moment(date.dateFirstSessionTo).format();
		}
		setLoadingActive(true);
		filters.page = pageTable;
		filters.quantity = loadForPage;
		filters.pagination = true;
		filters.orderby = '-FirstSessionDate';
		loadData(filters);
		setBodyFilter(filters);
		dispatch({ type: 'document/setFilters', payload: filters });
		dispatch(setFilters(filters));
	};

	const loadData = (filters) => {
		setLoadingActive(true);
		let filtersLoad = filters || dashboardRedux.filters;
		
			Promise.all([devolutionSection ? api.getReturns(filtersLoad) : api.getDocuments(filtersLoad), api.GetSocialWorkForSelect()])
			.then(resp=>{
				const listOfPatientOrReturns = resp[0].data.resultados;
				const listOfSocialWork = resp[1].data

				setDataTable(listOfPatientOrReturns);
				setQuantityData(resp[0].data.cantidadPaginas);

				const socialWorks = listOfSocialWork.map(item=>{
					return{
						id: item.id,
						name: item.nombre 
					}
				});
				setSocialWorks(socialWorks);

				const states = StatusDocuments.map(item=>{
					return{
						id: item.value,
						name: item.label 
					}
				});
				setStatus(states)
			})
			.catch(err=>{
				setDataToast({
					active: true,
					message: err.response.data.errores || "Acaba de ocurrir un error.",
					type: 'error',
				});
			})
			.finally(()=>{
				setFilterLoading(false);
				setLoadingActive(false);
				if(!devolutionSection){		
					hasAnyReturns();
				}
			})
	};
	return (
		<>
			<Grid style={{position: "relative"}}>
				<PopUpNotification 
					title={"Tiene devoluciónes de Pacientes pendientes."} 
					redirect={"/devoluciones/lista"} 
					show={!isAdmin && hasReturns.show} 
					quantity={hasReturns.total}
				/>
				<Card className='color-border-dash'>
					<CardContent className='center-responsive-filters'>
						<Grid.Row className='header-section'>
							<div className="titleAndVideo">
								{!isAdmin && !devolutionSection && (
									<Tooltip title='Alta de Pacientes'>
										<IconButton
											size='medium'
											href='https://www.youtube.com/watch?v=jvOi1KGNz4A&t=9s&ab_channel=OctusSoftware'
											target='_blank'
										>
											<Icon name='youtube' color='red' className='c-wp-20' />
										</IconButton>
									</Tooltip>
								)}
								{isProfessional && devolutionSection && 
									<Tooltip title='Devoluciones'>
										<IconButton
											size='medium'
											href='https://www.youtube.com/watch?v=j2lnVDikLYg&ab_channel=OctusSoftware'
											target='_blank'
										>
											<Icon name='youtube' color='red' className='c-wp-20' />
										</IconButton>
									</Tooltip>
								}
								<span style={{ fontSize: 27 }}>{title ? title : "Pacientes"}</span>
							</div>
							<div>
								{hasReturns.section == false && isCreate == true && 
									<Button
										className='btn-new-filter'
										onClick={() => handleClickNew()}
										icon
										labelPosition='left'
									>
										<Icon name='plus' />
										Nuevo
									</Button>
								}
							</div>
						</Grid.Row>
						<Grid.Row className="row-filterList content-filter-list">
							<InputFormLabel
								classInput='width-input-filter-dash filter-input'
								isRequired={false}
								type={'text'}
								label={'Paciente'}
								name={'patient'}
								control={control}
								error={errors}
							/>
							{isAdmin && <InputFormLabel
								classInput='width-input-filter-dash filter-input'
								isRequired={false}
								type={'text'}
								label={'Profesional'}
								name={'professional'}
								control={control}
								error={errors}
							/>}
							<SelectFilter
								label={'Obra Social'}
								name={'socialWork'}
								control={control}
								menuItemList={socialWorks}
								filtersLoading={filtersLoading}
							/>
							<InputFormLabel
								classInput='width-input-filter-dash filter-input'
								register={register({ required: false })}
								isRequired={false}
								type={'number'}
								label={'Nro. Autorización'}
								name={'numberAuth'}
								control={control}
								error={errors}
							/>
							<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
								<KeyboardDatePicker
									autoOk
									className='size-date-picke-filter filter-input'
									clearable
									style={{ margin: 5 }}
									value={dateFilter.dateFirstSessionFrom}
									placeholder='Fecha Desde'
									onChange={(date) =>
										setDateFilter({ ...dateFilter, dateFirstSessionFrom: date })
									}
									format='dd/MM/yyyy'
									margin='normal'
									inputVariant='outlined'
								/>
								<KeyboardDatePicker
									autoOk
									className='size-date-picke-filter filter-input'
									clearable
									style={{ margin: 5 }}
									value={dateFilter.dateFirstSessionTo}
									placeholder='Fecha Hasta'
									onChange={(date) =>
										setDateFilter({ ...dateFilter, dateFirstSessionTo: date })
									}
									format='dd/MM/yyyy'
									margin='normal'
									inputVariant='outlined'
								/>
							</MuiPickersUtilsProvider>
							{ isProfessional &&
									<SelectFilter
									label='Estado'
									name='status'
									control={control}
									menuItemList={status}
									filtersLoading={filtersLoading}
								/>
							}
						</Grid.Row>
						<Button
							className='btn-search-filter'
							primary
							onClick={() => handleClickSearch()}
							icon
							labelPosition='left'
						>
							<Icon name='search' />
							Buscar
						</Button>
					</CardContent>
				</Card>
				{loadingActive ? (
					<Dimmer className='loader-fixed' active inverted>
						<Loader size='big'>Cargando..</Loader>
					</Dimmer>
				) : (
					dataTable && (
						<DashboardList
							loadingActive={loadingActive}
							setLoadingActive={setLoadingActive}
							devolutionSection={devolutionSection}
							setDataToast={setDataToast}
							dataSweetAlert={dataSweetAlert}
							setDataSweetAlert={setDataSweetAlert}
							alertDelete={alertDelete}
							setAlertDelete={setAlertDelete}
							actionConfirm={actionConfirmDelete}
							reloadData={reloadData}
							dataTable={dataTable}
							handleClickNew={handleClickNew}
							totalCount={quantityData}
							loadForPage={loadForPage}
							dataPagination={dataPagination}
							setDataPagination={setDataPagination}
							setAlertEditEvent={setAlertEditEvent}
							setDataEditEvent={setDataEditEvent}
							loadData={loadData}
							dataEditEvent={dataEditEvent}
							alertEditEvent={alertEditEvent}
							dataToast={dataToast}
							setPage={setPageTable}
							page={pageTable}
							isView={isView}
							isEdit={isEdit}
							isDelete={isDelete}
							isExport={isExport}
							dateToday={dateToday}
							isProfessional={isProfessional}
							isAdmin={isAdmin}
							isSuperAdmin={isSuperAdmin}
						/>
					)
				)}
			</Grid>
		</>
	);
}
