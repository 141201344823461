import React from 'react';
import GeneralModalShow from '../../../utils/GeneralModalShow';
import GeneralModalTabs from '../../../utils/GeneralModalTabs';
import ReceiptForProfesional from '../../panelSection/ReceiptForProfesional';
import ReceiptForSocialWork from '../../panelSection/ReceiptForSocialWork';
import ReceiptForFree from '../../panelSection/ReceiptForFree';
import { StatusCode } from '../../../../enums';
import { GetReceiptById } from '../../../../services/services/Receipts';
import { IReceiptForFree, IReceiptForProfessional, IReceiptForSocialWork } from '../../../../services/interfaces/Managment/INewModalReceipt';
import { _DEFAULT_VALUES_RECEIPTS } from '../../defaultValues';
import { Dimmer, Loader } from 'semantic-ui-react';
import { IViewModalReceipts } from '../../../../services/interfaces/Managment/IViewModalReceipts';

const ViewModalReceipts: React.FC<IViewModalReceipts> = ({
    titleOfModal,
    isView,
    modalSelected,
    HandleCloseModals,
    MessageNotification
}) => {
    const [loadingActive, setLoadingActive] = React.useState(false);
    const [receiptProfessional, setReceiptProfessional] = React.useState<IReceiptForProfessional>(_DEFAULT_VALUES_RECEIPTS._VALUES_RECEIPT._PROFESSIONAL);
    const [receiptSocialWork, setReceiptSocialWork] = React.useState<IReceiptForSocialWork>(_DEFAULT_VALUES_RECEIPTS._VALUES_RECEIPT._SOCIALWORK);
    const [receiptFree, setReceiptFree] = React.useState<IReceiptForFree>(_DEFAULT_VALUES_RECEIPTS._VALUES_RECEIPT._FREE);
    const [panelSection, setPanelSection] = React.useState<string[]>(["Profesional", "Obra Social", "Libre"]);
    const [viewPanelDefault, setViewPanelDefault] = React.useState(_DEFAULT_VALUES_RECEIPTS._TABS_PANELS._PROFESSIONAL);

    React.useEffect(()=>{
        if (isView) GetReceiptToShow();
    },[]);

    async function GetReceiptToShow() {
        try{
            setLoadingActive(true);
            const {data, status} = await GetReceiptById(modalSelected.data.id as number);
            if (status === StatusCode.Ok){
                if (data.results.profesionalId){
                    const receipt: IReceiptForProfessional = {
                        sucursal: data.results.numeroSucursal,
                        numero: data.results.numero,
                        fecha: data.results.fecha,
                        monto: data.results.monto,
                        interes: data.results.interes,
                        enConceptoDe: data.results.concepto,
                        deposito: data.results.esDeposito,
                        afectaSaldo: data.results.afectaSaldo,
                        profesionalId: data.results.profesionalId
                    }
                    setReceiptProfessional(receipt);
                }
                if (data.results.obraSocialCuentaId || data.results.facturaId){
                    const receipt: IReceiptForSocialWork = {
                        sucursal: data.results.numeroSucursal,
                        numero: data.results.numero,
                        fecha: data.results.fecha,
                        monto: data.results.monto,
                        interes: data.results.interes,
                        enConceptoDe: data.results.concepto,
                        deposito: data.results.esDeposito,
                        facturaId: data.results.facturaId,
                        obraSocialId: data.results.obraSocialCuentaId
                    }
                    setReceiptSocialWork(receipt);
                    setViewPanelDefault(_DEFAULT_VALUES_RECEIPTS._TABS_PANELS._SOCIALWORK);
                }
                if (data.results.nombreLibre){
                    const receipt: IReceiptForFree = {
                        sucursal: data.results.numeroSucursal,
                        numero: data.results.numero,
                        fecha: data.results.fecha,
                        monto: data.results.monto,
                        interes: data.results.interes,
                        enConceptoDe: data.results.concepto,
                        deposito: data.results.esDeposito,
                        nombre: data.results.nombreLibre
                    }
                    setReceiptFree(receipt);
                    setViewPanelDefault(_DEFAULT_VALUES_RECEIPTS._TABS_PANELS._FREE);
                }
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores,"error");
        }finally{
            setLoadingActive(false);
        }
    }

    return (
        <>
        {loadingActive ? 
            <Dimmer className="loader-fixed" active inverted>
                <Loader size="big">Cargando...</Loader>
            </Dimmer>
        :
        <GeneralModalShow
        title={titleOfModal}
        HandleClose={HandleCloseModals}
        openModal={modalSelected.show}
        >
            <GeneralModalTabs
            isView={isView}
            viewPanelDefault={viewPanelDefault}
            arrPanels={[
                <ReceiptForProfesional 
                receiptProfessional={receiptProfessional}
                isView={isView}
                setReceiptProfessional={setReceiptProfessional}
                MessageNotification={MessageNotification}
                />,
                <ReceiptForSocialWork 
                receiptSocialWork={receiptSocialWork}
                isView={isView}
                setReceiptSocialWork={setReceiptSocialWork}
                MessageNotification={MessageNotification}
                />,
                <ReceiptForFree 
                receiptFree={receiptFree}
                isView={isView}
                setReceiptFree={setReceiptFree}
                />
            ]}
            arrTittleOfPanels={panelSection}
        />
        </GeneralModalShow>
        }
        </>
    );
}
export default ViewModalReceipts;