import { Checkbox, DialogContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from "semantic-ui-react";
import { setDataOfBillForLiquidation, setRestoreDataOfBillForliquidation } from '../../../../redux/generateLiquidation';
import ButtonBox from '../../../utils/ButtonBox';
import { FormatNumber } from '../../../utils/FormatInputs';
import GeneralModalCrud from '../../../utils/GeneralModalCrud';
import GeneralSimpleList from '../../../utils/GeneralSimpleList';
import { IAllBillsCheckedLiquidation, IBillLiquidationModal, IBillsForLiquidation, IModifiedBillLiquidation } from '../../../../services/interfaces/Managment/IBillsForLiquidation';
import { _DEFAULT_BILL_LIQUIDATION_VALUES } from '../../defaultValues';

const BillsForLiquidation: React.FC<IBillsForLiquidation> = ({MessageNotification, dataToRender, setDataToRender, loadingActive}) => {
    const {facturas}: any = useSelector<any>(({generateLiquidation}) => generateLiquidation);
    const disparador = useDispatch();
    const history = useHistory();
    const [modals, setModals] = React.useState<IBillLiquidationModal>(_DEFAULT_BILL_LIQUIDATION_VALUES._MODALS);

    function HandleCheckAllBillForLiquidation(e){
        if (e.target.checked){
            let allBillsChecked: IAllBillsCheckedLiquidation | {} = {};
            dataToRender.forEach(bill => {
                allBillsChecked = {
                    ...allBillsChecked,
                    [bill.id as number]: {
                        id: bill.id,
                        obraSocial: bill.obraSocial,
                        plan: bill.plan,
                        marcado: e.target.checked,
                        total: bill.monto,
                        fecha: bill.fecha,
                        porcentajeDebito: bill.porcentajeDebito,
                        montoDebitoAutomatico: bill.montoDebitoAutomatico,
                        montoDebitoManual: bill.montoDebitoManual ?? 0,
                        montoTotalDebito: bill.montoTotalDebito ?? 0,
                        usuarioFactura: facturas[bill.id as number]?.usuarioFactura.length > 0 ? facturas[bill.id as number].usuarioFactura : []
                    }
                }
            });
            disparador(setDataOfBillForLiquidation(allBillsChecked));
        }else{
            let billArr: IModifiedBillLiquidation[] = Object.values(facturas);
            let billsWithManualDebitId = billArr.filter(bill => {if (bill.usuarioFactura.length > 0) return bill.id}).map(billFiltered => billFiltered.id);

            if (billArr.some((bill) => facturas[bill.id as number] !== undefined && facturas[bill.id as number].usuarioFactura.length > 0)){
                setModals((prevState)=> ({...prevState, notificationManualDebit: {show: true, data: billsWithManualDebitId}}));
                return;
            }

            const billsMemorized: IModifiedBillLiquidation[] = Object.values(facturas);
            billsMemorized.forEach((bill: IModifiedBillLiquidation) => {
                if (facturas[bill.id as number] !== undefined){
                    disparador(setRestoreDataOfBillForliquidation(bill.id));
                }
            })
        }
    }
    function HandleCheckBill(e, billSelected: IModifiedBillLiquidation){
        if (facturas[billSelected.id as number] !== undefined && facturas[billSelected.id as number].usuarioFactura.length > 0 && facturas[billSelected.id as number].marcado !== false && !e.target.checked){
            setModals((prevState)=> ({...prevState, notificationManualDebit: {show: true, data: [billSelected.id]}}));
            return;
        }
        if (e.target.checked){
            disparador(setDataOfBillForLiquidation({
                [billSelected.id as number]: {
                    id: billSelected.id,
                    obraSocial: billSelected.obraSocial,
                    plan: billSelected.plan,
                    marcado: e.target.checked,
                    total: billSelected.monto,
                    fecha: billSelected.fecha,
                    porcentajeDebito: billSelected.porcentajeDebito,
                    montoDebitoAutomatico: billSelected.montoDebitoAutomatico,
                    montoDebitoManual: billSelected.montoDebitoManual ?? 0,
                    montoTotalDebito: billSelected.montoTotalDebito ?? 0,
                    usuarioFactura: facturas[billSelected.id as number]?.usuarioFactura.length > 0 ? facturas[billSelected.id as number].usuarioFactura : []
                }
            }));
        }else{
            if (facturas[billSelected.id as number] !== undefined){
                disparador(setRestoreDataOfBillForliquidation(billSelected.id));
            }
        }
    }
    function HandleCloseModals(){
        const allBillsMemorized: IModifiedBillLiquidation[] = Object.values(facturas);

        if (allBillsMemorized.length === dataToRender.length && allBillsMemorized.every((bill: IModifiedBillLiquidation) => bill.marcado)){
            allBillsMemorized.forEach((bill: any) => {
                if (bill.usuarioFactura.length <= 0){
                    disparador(setRestoreDataOfBillForliquidation(bill.id));
                }
            });
        }
        
        setModals((prevState)=>({...prevState, notificationManualDebit: {show: false, data: null}}));
    }
    function HandleRestoreToManualDebit(){
        const dataToRenderModified: IModifiedBillLiquidation[]= dataToRender.map((bill: IModifiedBillLiquidation)=>{
            if (modals.notificationManualDebit.data.includes(bill.id)){
                return {
                    ...bill,
                    montoDebitoManual: 0,
                    montoTotalDebito: 0
                }
            }else {
                return bill;
            }
        });
        modals.notificationManualDebit.data.forEach(billId => {
            disparador(setRestoreDataOfBillForliquidation(billId));
        });
        setDataToRender(dataToRenderModified);
        HandleCloseModals();
    }

    return (
        <>
        <GeneralSimpleList
        dataToRender={dataToRender}
        isLoaderActive={loadingActive}
        paginated={false}
        >
            <Table aria-label='spanning table'>
                <TableHead>
                    <TableRow>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <Checkbox 
                                checked={Object.values(facturas).length === dataToRender.length && Object.values(facturas).every((bill: any) => bill.marcado)} 
                                onChange={(e)=>HandleCheckAllBillForLiquidation(e)}/>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Número</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Obra Social /<br />Plan</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Fecha</b>
                        </TableCell>
                         <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Período</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Total</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Débito Aut. (%)</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Débito Manual</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Total Débito</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Número Recibo</b>
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            <b>Opciones</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataToRender.map((item: IModifiedBillLiquidation) => (
                        <TableRow key={item.id}>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <Checkbox 
                                    checked={facturas[item.id as number]?.marcado} 
                                    onChange={(e)=>HandleCheckBill(e, item)}/>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.id}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.obraSocial.nombre} <br />{item.plan.nombre}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {new Date(item.fecha).toLocaleDateString()}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.periodo.nombre}
                            </TableCell>
                             <TableCell className='letter-title-table c-text-center p-cell'>
                                {FormatNumber({style: 'currency', currency: 'USD', value: item.monto})}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                % {item.porcentajeDebito} <br /> {FormatNumber({style: 'currency', currency: 'USD', value: item.montoDebitoAutomatico})}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {FormatNumber({style: 'currency', currency: 'USD', value: item.montoDebitoManual})}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {FormatNumber({style: 'currency', currency: 'USD', value: item.montoTotalDebito})}
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                {item.reciboId}
                            </TableCell>
                            <TableCell className='c-text-center'>
                                <Button.Group>
                                    <ButtonBox buttonToShow='manualDebit' name="manualDebit" isDisabled={!facturas[item.id as number]?.marcado} HandleOnClik={(e)=>{history.push(`/liquidaciones/facturas/${item.id}/debitos`)}}/>
                                </Button.Group>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </GeneralSimpleList>

        {modals.notificationManualDebit.show &&
        <GeneralModalCrud
        titleOfModal="AVISO"
        openModal={modals.notificationManualDebit.show}
        HandleSubmit={HandleRestoreToManualDebit}
        HandleCloseModal={HandleCloseModals}
        >
            <DialogContent>
                <Grid>
                       <Typography>
                        La/s Factura/s N : 
                        {modals.notificationManualDebit.data.map((billId)=>(
                        <Typography>
                            {` - ${billId}`}
                        </Typography>
                        ))}
                        cuentan con débitos a profesionales asignados.
                       </Typography>
                    <Typography>¿Está seguro de desmarcar la/s siguiente/s Factura/s?</Typography>
                </Grid>
            </DialogContent>
        </GeneralModalCrud>}
        </>
    )
}

export default BillsForLiquidation;