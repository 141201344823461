import React from 'react';
import { ButtonGroup, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { FormatNumber } from '../../../utils/FormatInputs';
import GeneralSimpleList from '../../../utils/GeneralSimpleList';
import { ISummaryForProfessionalResponse } from '../../../../api/Interfaces/managment/ILiquidation';
import DownloadImage from "@material-ui/icons/GetApp";
import SearchIcon from '@material-ui/icons/Search';
import { StateOfLiquidation } from "../../../../enums";
import { makeStyles } from "@material-ui/core/styles";

const defaultCell = {
    padding: '12px 0px !important',
    textAlign: 'center',
    height: '64px !important',
    maxHeight: '64px !important',
    minHeight: '64px !important',
    '& b': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
};

const nthChildren = {
    '&:nth-child(1)': {
        width: '8% !important',
        minWidth: '8% !important',
        ...defaultCell,
    },
    '&:nth-child(2)': {
        width: '12% !important',
        minWidth: '12% !important',
        ...defaultCell,
    },
    '&:nth-child(n+3)': {
        width: '8% !important',
        minWidth: '8% !important',
        ...defaultCell,
    },
};

const useStyles = makeStyles((theme) => ({
    header: {
        borderRadius: '5px 5px 0px 0px',
        height: '64px !important',
    },
    row: {
        height: '64px !important',

        '& th': {
            ...nthChildren,
            '& b': {
                color: 'white !important', // Cambia el color del texto a blanco
            },
        },
        '& td': {
            ...nthChildren,

        },
    },
}));

function SummaryForProfessional({ DownloadProfitRetention, dataToRender, liquidationStatus, HandleViewProfessionalBillsDetails }) {
    const classes = useStyles();

    window.addEventListener('scroll', () => {
        const header = document.getElementById('menuHeader');
        const tableHeader = document.getElementById('tableHeader');

        if (header && tableHeader) {
            const table = document.getElementById('tableResumenes');
            const clearStyles = () => {
                tableHeader.style.removeProperty('position');
                tableHeader.style.removeProperty('top');
                tableHeader.style.removeProperty('left');
                tableHeader.style.width = table?.getBoundingClientRect().width + 'px';
                (tableHeader.children[0] as HTMLElement).style.width = table?.getBoundingClientRect().width + 'px';
                tableHeader.style.removeProperty('z-index');
                if (table) {
                    table.style.removeProperty('margin-top');
                }
            }

            //validacion
            if (window.scrollY >= 371) {
                tableHeader.style.position = 'fixed';
                tableHeader.style.top = '53px';
                tableHeader.style.left = '210px';
                tableHeader.style.width = table?.getBoundingClientRect().width + 'px';
                (tableHeader.children[0] as HTMLElement).style.width = table?.getBoundingClientRect().width + 'px';
                tableHeader.style.zIndex = '1';
                tableHeader.style.height = '64px';
                if (table) table.style.marginTop = '60px';
            } else if (table && tableHeader) { clearStyles() }
        }
    })


    return (
        <GeneralSimpleList
            dataToRender={dataToRender}
            isLoaderActive={false}
            paginated={false}
        >
            <Table aria-label='spanning table' id="tableResumenes">
                <TableHead id="tableHeader" className={classes.header}>
                    <TableRow className={classes.row}>
                        <TableCell id="matr">
                            <b>Matr.</b>
                        </TableCell>
                        <TableCell id="prof">
                            <b>Profesional</b>
                        </TableCell>
                        <TableCell id="totalOS">
                            <b>Total Obra Social</b>
                        </TableCell>
                        <TableCell id="totalDeb">
                            <b>Total Débitos</b>
                        </TableCell>
                        <TableCell id="totalAcr">
                            <b>Total Acred.</b>
                        </TableCell>
                        <TableCell id="totalRet">
                            <b>Total Retenciones</b>
                        </TableCell>
                        <TableCell id="total">
                            <b>Total</b>
                        </TableCell>
                        <TableCell id="saldoAnt">
                            <b>Deuda Saldo Anterior</b>
                        </TableCell>
                        <TableCell id="saldoAct">
                            <b>Deuda Saldo Actual</b>
                        </TableCell>
                        <TableCell id="deudaCancel">
                            <b>Deuda Cancelada</b>
                        </TableCell>
                        <TableCell id="neto">
                            <b>Neto</b>
                        </TableCell>
                        <TableCell id="opc">
                            <b>Opc.</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody id="tableBody">
                    {dataToRender.map((item: ISummaryForProfessionalResponse, index) => (
                        <TableRow className={classes.row} key={item.id} id={`tableBody-row:${index}`}>
                            <TableCell id="matr" className='letter-title-table min-grilla' style={{ width: '88.45px !important' }}>
                                {item.matriculaProfesional}
                            </TableCell>
                            <TableCell id="prof" className='letter-title-table min-grilla' style={{ width: '190.92px !important' }}>
                                {item.profesional.nombre}
                            </TableCell>
                            <TableCell id="totalOS" className='letter-title-table min-grilla' style={{ width: '117.15px !important' }}>
                                {FormatNumber({ style: "currency", currency: "USD", value: item.totalObraSocial })}
                            </TableCell>
                            <TableCell id="totalDeb" className='letter-title-table min-grilla' style={{ width: '92.48px !important' }}>
                                {FormatNumber({ style: "currency", currency: "USD", value: item.totalDebitos })}
                            </TableCell>
                            <TableCell id="totalAcr" className='letter-title-table min-grilla' style={{ width: '76.58px !important' }}>
                                {FormatNumber({ style: "currency", currency: "USD", value: item.totalAcreditaciones })}
                            </TableCell>
                            <TableCell id="totalRet" className='letter-title-table min-grilla' style={{ width: '118.26px !important' }}>
                                {FormatNumber({ style: "currency", currency: "USD", value: item.totalRetenciones })}
                            </TableCell>
                            <TableCell id="total" className='letter-title-table min-grilla' style={{ width: '96.28px !important' }}>
                                {FormatNumber({ style: "currency", currency: "USD", value: item.total })}
                            </TableCell>
                            <TableCell id="saldoAnt" className='letter-title-table min-grilla' style={{ width: '88.45px !important' }}>
                                {FormatNumber({ style: "currency", currency: "USD", value: item.deudaSaldoAnterior })}
                            </TableCell>
                            <TableCell id="saldoAct" className='letter-title-table min-grilla' style={{ width: '88.45px !important' }}>
                                {FormatNumber({ style: "currency", currency: "USD", value: item.deudaSaldoActual })}
                            </TableCell>
                            <TableCell id="deudaCancel" className='letter-title-table min-grilla' style={{ width: '88.45px !important' }}>
                                {FormatNumber({ style: "currency", currency: "USD", value: item.deudaCancelada })}
                            </TableCell>
                            <TableCell id="neto" className='letter-title-table min-grilla' style={{ width: '88.45px !important' }}>
                                {FormatNumber({ style: "currency", currency: "USD", value: item.neto })}
                            </TableCell>
                            <TableCell id="opc" className='letter-title-table min-grilla' style={{ width: '88.45px !important' }}>
                                <ButtonGroup>
                                    <IconButton
                                        className='btn-circle-blue'
                                        aria-label='search'
                                        onClick={() => HandleViewProfessionalBillsDetails(item)}
                                    >
                                        <Tooltip placement="top" title="Ver Profesional Detallado">
                                            <SearchIcon />
                                        </Tooltip>
                                    </IconButton>
                                    {item.tieneRetencionGanancia && liquidationStatus === StateOfLiquidation.generada &&
                                        <IconButton
                                            className='btn-circle-yellow'
                                            onClick={() => DownloadProfitRetention(item)}
                                        >
                                            <Tooltip placement="top" title="Descargar Retención de ganancias">
                                                <DownloadImage />
                                            </Tooltip>
                                        </IconButton>
                                    }
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </GeneralSimpleList>
    );
}

export default SummaryForProfessional;
