import { createSlice } from "@reduxjs/toolkit";

const {actions, reducer} = createSlice({
    name: 'modal',
    initialState: {
        integrationModal: false
    },
    reducers: {
        setModal(state, {payload}){
            state.integrationModal = payload
        }
    }
});

export const {setModal} = actions;
export default reducer;