import { Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { IntegrationSocialWork, StatusCode } from '../../../enums';
import GeneralList from '../../utils/GeneralList';
import GeneralModalCrud from '../../utils/GeneralModalCrud';
import { makeStyles } from "@material-ui/core/styles";

const customStyles = makeStyles(()=>({
    btnClearFilter: {
        width: 40, 
        height: 40, 
        borderRadius: "100%", 
        minWidth: "unset", 
        alignSelf: "end"
    },
    contDiagnosticModal: {width: "auto", minWidth: "unset"}
}))

const DiagnosticModal: React.FC<any> = ({idSocialWork, 
    setDiagnosticModal, 
    openDiagnosticModal, 
    setOpenDiagnosticModal, 
    diagnosticModal,
    setDataToast}) =>{
    const [filterObj, setFilterObj] = useState<any>({diagnostic: null});
    const [diagnosticList, setDiagnosticList] = useState({count: 99, results: []});
    const [isLoaderActive, setIsloaderActive] = useState(false);
    const [initialPage, setInitialPage] = useState(1);
    const [state, setState] = useState({});
    const [diagnosticSelected, setDiagnosticSelected] = useState(diagnosticModal.data);
    const customClassStyle = customStyles();

    useEffect(()=>{
        if (idSocialWork.id === IntegrationSocialWork.apross) AsyncOptionsForSelect();
    },[]);

    const FilterComponents = ()=> {
        return( 
        <div style={{display: "flex"}}>
        <TextField
            value={filterObj.diagnostic}
            label='Diagnóstico'
            type='text'
            InputLabelProps={{shrink: true}}
            onBlur={(e)=>setFilterObj({diagnostic: e.target.value.trim() === "" ? null : e.target.value})}
        />
        <Button className={`btn-circle-red ${customClassStyle.btnClearFilter}`} onClick={()=>setFilterObj({diagnostic: null})}>X</Button>
        </div>)
    }

    const AsyncOptionsForSelect = async(page = 1)=>{
		try{
            setIsloaderActive(true);
			const {data, status} = await api.GetDiagnostics({obraSocialId: idSocialWork.id, pagina: page, busqueda: filterObj.diagnostic});
            if (status === StatusCode.Ok){

                //en la prop results va la lista de diagnosticos de la api de apross
                setDiagnosticList({...diagnosticList, results: data}); 

                //stateItems se convierte en un array de objetos donde la key es el codigo del diagnostico
                const stateItems = data.map(item=>{
                    return {[item.codigoCie]: false}
                });

                //todos los valores del array se reducen y se integran dentro de un objeto donde quedaria un objeto
                //donde la key es el codigo del diagnostico
                let objetoFinal = stateItems.reduce((acumulador, stateItems) => {
                    return { ...acumulador, ...stateItems };
                  }, {});

                  //si ya hay un diagnostico seleccionado entonces lo agregamos al state para que aparezca en checked en la lista
                  //caso contrario, utilizamos los diagnosticos del response de la api
                  if (diagnosticModal.data.codigo !== null && diagnosticModal.data.descripcion !== null){
                    setState({...objetoFinal, [diagnosticModal.data?.codigo]: true});
                  }else{
                    setState(objetoFinal); 
                  }
            }
		}catch(err: any){
			MessageNotifiaction(err.response.data.errores,"error");
		}finally{
            setIsloaderActive(false);
        }
	}
	function HandleInputChange(e, diagnostic){
        if (e.target.checked && Object.values(state).some(item=> item === true)) {
            MessageNotifiaction("Solo se puede seleccionar un diagnóstico a la vez.","error");
            return
        }

		setState({...state, [e.target.name]: e.target.checked});
        setDiagnosticSelected({codigo: !e.target.checked ? null : diagnostic.codigoCie, descripcion: !e.target.checked ? null : diagnostic.descripcion});
	}
    function HandleChangePage(event, pageSelected){
        setInitialPage(pageSelected);
        AsyncOptionsForSelect(pageSelected);
    }
    function HandleSubmit(){
        if (Object.values(diagnosticSelected).some(item=> item === null)) {
            MessageNotifiaction("Debe seleccionar al menos un diagnóstico.","error");
            return
        }
        setDiagnosticModal((prev)=>{return {...prev, data: diagnosticSelected}});
        setOpenDiagnosticModal(false);
    }
    function MessageNotifiaction(message:string, type:string){
        setDataToast({
            type: type,
            active: true,
            message: message
        });
    }
    return(
        <GeneralModalCrud
        openModal={openDiagnosticModal}
        HandleCloseModal={()=>setOpenDiagnosticModal(false)}
        HandleSubmit={HandleSubmit}
        styles={customClassStyle.contDiagnosticModal}
        titleOfModal=''>
            <GeneralList
            titleOfSection="Diagnósticos"
            isLoaderActive={isLoaderActive}
            dataToRender={diagnosticList.results}
            numPagesToRender={diagnosticList.count}
            FilterComponent={FilterComponents}
            initialPage={initialPage}
            HandleSearch={AsyncOptionsForSelect}
            onChangePage={HandleChangePage}
            >
                <Table aria-label='spanning table'>
                    <TableHead>
                        <TableRow>
                            <TableCell className='letter-title-table c-text-center'>
                                <b>Elegir</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center'>
                                <b>Descripción</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center'>
                                <b>Código</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {diagnosticList.results.map((item: any) => (
                            <TableRow key={item.value}>
                                <TableCell className='letter-title-table c-text-center'>
                                    <Checkbox
                                    disabled={false}
                                    name={`${item.codigoCie}`}
                                    onClick={(e)=> HandleInputChange(e, item)}
                                    className="no-padding-checkbox p-cell"
                                    checked={state[item.codigoCie]}
                                    color="primary"
                                    />
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center'>
                                {item.descripcion}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center'>
                                {item.codigoCie}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </GeneralList>
        </GeneralModalCrud>
    );
}

export default DiagnosticModal;