import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import React from 'react'
import { Button } from 'semantic-ui-react'
import { IGeneralModalCrud } from '../../services/interfaces/utils/IGeneralModalCrud'

const objStyle = makeStyles({
    alignButton: {textAlign: 'center', justifyContent: 'center', zIndex: 100}
})

const GeneralModalCrud: React.FC<IGeneralModalCrud> = ({
    openModal, 
    HandleCloseModal, 
    HandleSubmit, 
    titleOfModal, 
    children, 
    styles})=>{
    const customStyles = objStyle();
    return(
        <Dialog
        open={openModal}
        onClose={HandleCloseModal}
        aria-labelledby='form-dialog-title'
        >
            <DialogTitle id='form-dialog-title'>{titleOfModal}</DialogTitle>

            <DialogContent className={styles ? styles : ""} >
                {children}
            </DialogContent>

            <DialogActions
                className={customStyles.alignButton} >
                <Button onClick={HandleCloseModal}>Cancelar</Button>
                <Button onClick={HandleSubmit} color='green'>Aceptar</Button>
            </DialogActions>
        </Dialog>	
    )
}

export default GeneralModalCrud;