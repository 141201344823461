const _DEFAULT_STATE_CHECKBOX = {
    convenio: false,
    autorizacion: false,
    dadasDeBaja: false,
    dadasDeBajaIcon: false,
    nroSiniestro: false,
    aplicaCoseguro: false,
    integracion: false,
    requiereToken: false,
    obraSocialDelColegio: false,
    individualUpdate: false,
}
const _DEFAULT_VALUES = {
    id: null,
    nombre: null,
    cantidadMaximaSesiones: 0,
    debitoAutomatico: 0,
    alias: null,
    apikey: null,
    clave: null,
    codigoPrestador: null,
    terminal: null,
    email: null,
    cuentaId: null,
    requiereToken: false,
    esObraSocialColegio: false,
    afiliadoCantidadCaracteresMin: null,
    afiliadoCantidadCaracteresMax: null
}
const _DEFAULT_MODAL_VALUES = {
    create: {show: false, data: null},
    edit: {show: false, data: null},
    view: {show: false, data: null},
    delete: {show: false, data: null},
    reactivate: {show: false, data: null},
    instructive: {show: false, data: null},
    massiveEditSocialWork: {show: false, data: null},
    massiveEditAmount: {show: false, data: null},
    massiveAssignPractice: {show: false, data: null}
}

const _DEFAULT_ERROR_VALUES = {
    obraSocialId: false,
    cantMaximaSesiones: false,
    cuentaId: false,
    apiKey: false,
    clave: false,
    email: false,
    terminal: false,
    codigoPrestador: false
}

const _DEFAULT_FILTROS_VALUES = {
    obraSocial: {value: null, label: null},
    cuenta: {value: null, label: null},
    confirmadas: false
}

const _DEFAULT_REACTIVE_VALUES = {
    reactivateModal: false,
    showReactivateIcon: false
}

const _DEFAULT_LOADER_SELECT = {
    socialWork: false,
    account: false
}

const _DEFAULT_VALUES_ASSIGN_AMOUNT_TO_PRACTICE = {
    _ERROR_NOTIFICATION: {
        date: false,
    }
}

const _DEFAULT_VALUES_ModalAmountForOs = {
    _LOADER_SELECT: {
        socialWork: false,
        practice: false
    },
    _ERROR_NOTIFICATION: {
        socialWorkId: false,
        date: false
    },
    _DATA_TO_ASSIGN: {
        date: null,
        socialWorkId: null
    }
}
export {
    _DEFAULT_VALUES,
    _DEFAULT_STATE_CHECKBOX,
    _DEFAULT_MODAL_VALUES,
    _DEFAULT_ERROR_VALUES,
    _DEFAULT_FILTROS_VALUES,
    _DEFAULT_REACTIVE_VALUES,
    _DEFAULT_LOADER_SELECT,
    _DEFAULT_VALUES_ASSIGN_AMOUNT_TO_PRACTICE,
    _DEFAULT_VALUES_ModalAmountForOs
}