import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IReceiptFilterRequest, IReceiptRequest, IReceiptResponse } from "../../api/Interfaces/managment/IReceipt";
import errorHandler from "../errorHandler";

const GetReceiptList = async (params: IReceiptFilterRequest): Promise<IApiResponse<IReceiptResponse[]>> => {
    try{
        const response = await api.GetReceiptList(params);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetReceiptById = async (receiptId: number): Promise<IApiResponse<IReceiptResponse>> => {
    try{
        const response = await api.GetReceiptById(receiptId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetPrintReceipt = async (receiptId: number): Promise<IApiResponse<any>> => {
    try{
        const response = await api.GetPrintReceipt(receiptId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CreateReceipt = async (params: IReceiptRequest): Promise<IApiResponse<number>> => {
    try{
        const response = await api.CreateReceipt(params);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const DeleteReceipt = async (receiptId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.DeleteReceipt(receiptId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}


export {GetReceiptList, CreateReceipt, GetReceiptById, DeleteReceipt, GetPrintReceipt};