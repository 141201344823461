import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { es } from 'date-fns/locale';
import React from 'react';
import { selectTypeFormat } from "../../../services/interfaces/utils/IUtilDeclaration";
import CustomReactSelect from "../../utils/CustomReactSelect";
import { _DEFAULT_VALUES_RECEIPTS } from "../defaultValues";
import { IDataForSelect, ILoaderForSelect } from "../../../services/interfaces/Managment/IReceipts";
import { GetBillsSelect } from "../../../services/services/CrudBill";
import { GetSocialWorkSelect } from "../../../services/services/ConfigurationSocialWork";
import { IReceiptSocialWork } from "../../../services/interfaces/Managment/IReceiptForProfesional";
import { Dimmer, Loader } from "semantic-ui-react";

const ReceiptForSocialWork: React.FC<IReceiptSocialWork> = ({
    receiptSocialWork,
    errorObj,
    isView,
    setReceiptSocialWork,
    MessageNotification
}) => {
    const [loaderForSelect, setLoaderForSelect] = React.useState<ILoaderForSelect>(_DEFAULT_VALUES_RECEIPTS._LOADER_FOR_SELECT);
    const [dataForSelect, setDataForSelect] = React.useState<IDataForSelect>(_DEFAULT_VALUES_RECEIPTS._DATA_FOR_SELECT);
    const [loadingActive, setLoadingActive] = React.useState(false);

    React.useEffect(()=>{
        GetAllAditionalData();
    },[]);

    async function GetAllAditionalData(){
        try{
            (isView && setLoadingActive(true));
            setLoaderForSelect({
            ...loaderForSelect,
                bills: true,
                socialWorks: true
            });
            const [billList, socialWorkList] = await Promise.all([GetBillsSelect(), GetSocialWorkSelect({})]);

            setDataForSelect({
                ...dataForSelect,
                billList: billList.data.results.map(bill => ({value: bill.id, label: `${bill.id} - ${bill.nombre}`})),
                socialWorkList: socialWorkList.data.results.map(os => ({value: os.id, label: os.nombre}))
            });
        }catch(err: any){
            MessageNotification(err.response.data.errores,"error");
        }finally{
            (isView && setLoadingActive(false));
            setLoaderForSelect({
            ...loaderForSelect,
                bills: false,
                socialWorks: false
            });
        }
    }

    //#region UTILS
    function HandleChangeInputSocialWorkReceipt(input){
        setReceiptSocialWork({
            ...receiptSocialWork,
            [input.target.name]: input.target.value === "" ? null : input.target.value
        });
    }
    function HandleChangeNumberInputSocialWorkReceipt(input){
        setReceiptSocialWork({
            ...receiptSocialWork,
            [input.target.name]: input.target.value === "" ? null : +input.target.value
        });
    }
     function HandleChangeCheckboxSocialWork(selectedCheckbox: any){
        setReceiptSocialWork({
            ...receiptSocialWork,
            [selectedCheckbox.target.name]: selectedCheckbox.target.checked
        });
    }
    function HandleChangeDates(date: MaterialUiPickersDate, name: string){
        setReceiptSocialWork({
            ...receiptSocialWork,
            [name]: date
        });
    }
    function HandleChangeSelectReceiptSocialWork(optionSelect: selectTypeFormat | null, aditionalData:any){
        setReceiptSocialWork({
            ...receiptSocialWork,
            [aditionalData.name]: optionSelect !== null ? optionSelect.value : null
        });
    }
    //#endregion
    
    return(
        <>
        {loadingActive ?
            <Dimmer className="loader-fixed" active inverted>
                <Loader size="big">Cargando...</Loader>
            </Dimmer>
            :
            <Grid className='cont-receipt'>
                <Grid className='cont-receipt cont-receipt--grid'>
                <TextField
                value={receiptSocialWork.sucursal}
                label='Sucursal'
                type='number'
                error={errorObj?.sucursal}
                id="sucursal"
                disabled={isView}
                variant="outlined"
                size="small"
                name='sucursal'
                InputLabelProps={{shrink: true}}
                InputProps={{ inputProps: {max: 9999999, min: 0}}}
                onChange={(e)=>HandleChangeNumberInputSocialWorkReceipt(e)}
                />
                <TextField
                value={receiptSocialWork.numero}
                label='Número'
                type='number'
                name='numero'
                id="numero"
                disabled={isView}
                error={errorObj?.numero}
                InputProps={{ inputProps: {max: 9999999, min: 0}}}
                variant="outlined"
                size="small"
                InputLabelProps={{shrink: true}}
                onChange={(e)=>HandleChangeNumberInputSocialWorkReceipt(e)}
                />
                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    autoOk
                    style={{margin: 0}}
                    className="size-date-picke-filter"
                    clearable
                    value={receiptSocialWork.fecha}
                    label="Fecha"
                    id="fecha"
                    disabled={true}
                    error={errorObj?.fecha}
                    name='fecha'
                    placeholder="Fecha"
                    onChange={(e)=>HandleChangeDates(e, "fecha")}
                    format="dd/MM/yyyy"
                    margin="normal"
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputVariant="outlined"
                    />
                </MuiPickersUtilsProvider>
                </Grid>
                <Grid className='cont-receipt cont-receipt--grid'>
                    <CustomReactSelect
                    placeholder="Obras Sociales..."
                    label="Obra Social"
                    name="obraSocialId"
                    isDisabled={isView}
                    customStyles={errorObj?.obraSocialId && "errorNotificationInput"}
                    isLoading={loaderForSelect.socialWorks}
                    options={dataForSelect.socialWorkList}
                    value={dataForSelect.socialWorkList.find(os => os.value === receiptSocialWork.obraSocialId) ?? null}
                    setValue={(e, opts)=>HandleChangeSelectReceiptSocialWork(e, opts)}
                    />
                    <CustomReactSelect
                    placeholder="Facturas..."
                    label="Factura"
                    name="facturaId"
                    isDisabled={isView}
                    customStyles={errorObj?.facturaId && "errorNotificationInput"}
                    isLoading={loaderForSelect.bills}
                    options={dataForSelect.billList}
                    value={dataForSelect.billList.find(bill => bill.value === receiptSocialWork.facturaId) ?? null}
                    setValue={(e, opts)=>HandleChangeSelectReceiptSocialWork(e, opts)}
                    />
                </Grid>
                <Grid className='cont-receipt cont-receipt--grid'>
                    <TextField
                    value={receiptSocialWork.monto}
                    label='Monto'
                    id="monto"
                    disabled={isView}
                    type='number'
                    InputProps={{ inputProps: {max: 9999999, min: 0}}}
                    name='monto'
                    error={errorObj?.monto}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{shrink: true}}
                    onChange={(e)=>HandleChangeNumberInputSocialWorkReceipt(e)}
                    />
                    <TextField
                    value={receiptSocialWork.interes}
                    label='Interés'
                    type='number'
                    disabled={isView}
                    id="interes"
                    InputProps={{ inputProps: {max: 9999999, min: 0}}}
                    error={errorObj?.interes}
                    name='interes'
                    variant="outlined"
                    size="small"
                    InputLabelProps={{shrink: true}}
                    onChange={(e)=>HandleChangeNumberInputSocialWorkReceipt(e)}
                    />
                    <Grid>
                        <FormControlLabel
                        control={
                            <Checkbox
                                disabled={isView}
                                checked={receiptSocialWork.deposito}
                                name="deposito"
                                onChange={(e)=>HandleChangeCheckboxSocialWork(e)}
                            />
                        }
                        label='Deposito'
                        />
                    </Grid>
                </Grid>
                <Grid className='cont-receipt cont-receipt--grid'>
                    <TextField
                    value={receiptSocialWork.enConceptoDe}
                    label='En concepto de:'
                    type='text'
                    disabled={isView}
                    id="enConceptoDe"
                    variant="outlined"
                    error={errorObj?.enConceptoDe}
                    size="small"
                    name='enConceptoDe'
                    InputLabelProps={{shrink: true}}
                    onChange={(e)=>HandleChangeInputSocialWorkReceipt(e)}
                    />
                </Grid>
            </Grid>
            }
        </>
    );
}

export default ReceiptForSocialWork;