import { createSlice } from "@reduxjs/toolkit";

const { actions, reducer } = createSlice({
	name: 'socialWork',
	initialState: {
		dataTable: [],
		filters: {
			withPeriod: false,
		},
		filtroFecha: {
			fecha: null,
		},
		filtroData: {
			data: null,
		},
	},
	reducers: {
		setDataTable(state, { payload }) {
			state.dataTable = payload || [];
		},
		setFilter(state, { payload }) {
			state.filters = payload || {};
		},
		setFiltroFecha(state, action) {
			state.filtroFecha.fecha = action.payload || null;
		},
		setFiltroData(state, action) {
			state.filtroData.data = action.payload || null;
		},
	},
});

export const {
	setDataTable,
	setFilter,
	setFiltroFecha,
	setFiltroData,
} = actions;

export default reducer;
